import { PigeonStateEnum } from "../../../models/enums/PigeonStateEnum";
import SelectOption from "../../../models/SelectOption";

const StateOptions = [
  new SelectOption({
    key: PigeonStateEnum.Borrowed,
    text: "Borrowed",
  }),
  new SelectOption({
    key: PigeonStateEnum.Breeding,
    text: "Breeding",
  }),
  new SelectOption({
    key: PigeonStateEnum.Dead,
    text: "Dead",
  }),
  new SelectOption({
    key: PigeonStateEnum.Lost,
    text: "Lost",
  }),
  new SelectOption({
    key: PigeonStateEnum.Sold,
    text: "Sold",
  }),
  new SelectOption({
    key: PigeonStateEnum.Widower,
    text: "Widower",
  }),
  new SelectOption({
    key: PigeonStateEnum.Unknown,
    text: "Other",
  }),
].sort((a, b) => a.text.localeCompare(b.text));

export const getInitialState = (current?: PigeonStateEnum) => {
  const option = StateOptions.find((state) => state.key === current);
  if (option) return option;
  return (
    StateOptions.find((state) => state.key === PigeonStateEnum.Unknown) ||
    StateOptions[0]
  );
};

export default StateOptions;

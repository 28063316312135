import {
  ListDomRef,
  ListItemStandard,
  ListItemStandardDomRef,
  SelectDialog,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import { memo, useEffect, useState } from "react";

import ListGrowingMode from "@ui5/webcomponents/dist/types/ListGrowingMode";
import ListSelectionMode from "@ui5/webcomponents/dist/types/ListSelectionMode";
import PageOptions from "../../models/PageOptions";
import Pigeon from "../../models/Pigeon";
import { getText } from "../../locales/initI18n";
import usePigeons from "../../hooks/usePigeons";

export interface PigeonSelectDialogProps {
  initFilters?: string;
  isVisible: boolean;
  onClose: (value?: Pigeon) => void;
}

const PigeonSelectDialog = ({
  onClose,
  isVisible = false,
  initFilters = "",
}: PigeonSelectDialogProps) => {
  const [filters, setFilters] = useState<string>(initFilters);
  const { pigeons, fetchNextPage, lastMeta, refetch } = usePigeons(
    new PageOptions({ filters })
  );

  const getObject = (id: string) => {
    return pigeons.find((f) => f.id.toString() === id);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <SelectDialog
      open={isVisible}
      onSearch={(event: Ui5CustomEvent<unknown, { value: string }>) => {
        const value = event.detail.value;
        if (value === "") return setFilters("");
        if (!value) return;
        setFilters(`ring.number=='=LIKE=${value}=LIKE='`);
      }}
      headerText={getText("PigeonSelectDialog.HeaderText")}
      selectionMode={ListSelectionMode.Single}
      growing={lastMeta?.isNext ? ListGrowingMode.Button : ListGrowingMode.None}
      onLoadMore={() => {
        fetchNextPage();
      }}
      onConfirm={(
        event: Ui5CustomEvent<
          ListDomRef,
          { selectedItems: ListItemStandardDomRef[] }
        >
      ) => {
        const item = event.detail.selectedItems[0];
        const id = item.dataset.id;
        if (!id) return onClose();
        const object = getObject(id);
        onClose(object);
      }}
      onClose={() => onClose()}
      onCancel={() => onClose()}
    >
      {pigeons?.map((item) => (
        <ListItemStandard
          key={`PigeonSelectDialog-${item.id}`}
          data-id={item.id}
        >
          {item.ring.number}
        </ListItemStandard>
      ))}
    </SelectDialog>
  );
};

export default PigeonSelectDialog;

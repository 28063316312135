import { Base } from "./Base";
import { EggState } from "./enums/EggStateEnum";
import Pigeon from "./Pigeon";
import PigeonPair from "./PigeonPair";
import User from "./User";

export default class Egg extends Base {
  pigeon!: Pigeon;
  pigeonId!: number;
  pigeonPair = new PigeonPair();
  biologicalPigeonPair = new PigeonPair();
  dateOfLaying!: Date;
  status!: EggState;
  isTransferred!: boolean;
  user = new User();
  brood!: number;

  fillData(data: object): this {
    super.fillData(data);
    if (this.pigeon) this.pigeon = Pigeon.create(this.pigeon);
    this.pigeonPair = PigeonPair.create(this.pigeonPair);
    this.biologicalPigeonPair = PigeonPair.create(this.biologicalPigeonPair);
    this.user = new User().fillData(this.user);
    return this;
  }
}

import {
  getEventsCount,
  getEventsFlights,
  getNewSharedCount,
  getPrivateEventsCount,
  getPrivateEventsFlights,
  getSharedFlights,
  getUserFlights,
} from "../services/FlightService";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { Flight } from "../models/Flight";
import { FlightFilterEnum } from "../models/enums/FlightFilterEnum";
import PageOptions from "../models/PageOptions";

const useFlights = (tab: FlightFilterEnum, options = new PageOptions()) => {
  const queryClient = useQueryClient();
  function getQueryKey(tabOrFlight: FlightFilterEnum | Flight) {
    let currentTab: FlightFilterEnum;
    if (tabOrFlight instanceof Flight) {
      if (tabOrFlight.isPrivateEvent())
        currentTab = FlightFilterEnum.InternalOpen;
      else if (tabOrFlight.isEvent()) currentTab = FlightFilterEnum.Open;
      else if (tabOrFlight.isSingle()) currentTab = FlightFilterEnum.Private;
      else currentTab = FlightFilterEnum.Shared;
    } else currentTab = tabOrFlight;
    switch (currentTab) {
      case FlightFilterEnum.Open:
        return "flight/event";
      case FlightFilterEnum.Private:
        return "flight/private";
      case FlightFilterEnum.Shared:
        return "flight/shared";
      case FlightFilterEnum.InternalOpen:
        return "flight/related";
    }
  }
  const flightQueryFn = (options: PageOptions) => {
    switch (tab) {
      case FlightFilterEnum.Open:
        return getEventsFlights(options);
      case FlightFilterEnum.Shared:
        return getSharedFlights(options);
      case FlightFilterEnum.InternalOpen:
        return getPrivateEventsFlights(options);
      default:
        return getUserFlights(options);
    }
  };
  const removeCache = (removeFromTab: FlightFilterEnum | Flight) => {
    queryClient.removeQueries([getQueryKey(removeFromTab)]);
    queryClient.removeQueries([`${getQueryKey(removeFromTab)}/count`]);
  };
  const queryFlights = useInfiniteQuery({
    queryKey: [options.getQueryKey(getQueryKey(tab))],
    queryFn: () => flightQueryFn(options),
    getNextPageParam: options.nextPagefn,
  });
  const { data: eventsCount } = useQuery({
    queryKey: ["flight/event/count"],
    refetchInterval: 60000 * 5,
    queryFn: () => getEventsCount(),
  });
  const { data: privateEventsCount } = useQuery({
    queryKey: ["flight/privateEvent/count"],
    refetchInterval: 60000 * 5,
    queryFn: () => getPrivateEventsCount(),
  });
  const { data: newSharedCount } = useQuery({
    queryKey: ["flight/shared/count"],
    refetchInterval: 60000 * 5,
    queryFn: () => getNewSharedCount(),
  });

  return {
    queryFlights,
    count: {
      private: privateEventsCount,
      shared: newSharedCount,
      events: eventsCount,
    },
    removeCache,
  };
};

export default useFlights;

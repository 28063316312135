import { authGet, authPost } from "./BaseService";

import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import Ring from "../models/Ring";

export const getRings = async (options: PageOptions) => {
  const result = await authGet<object[]>(`ring?${options.getParams()}`);

  const page = new Page<Ring>().fillData(result.data);
  page.data = page.data.map((item) => Ring.create(item));
  return page;
};

export const addRing = async (ring: string) => {
  return await authPost<Ring>("ring", { number: ring });
};

import { LegacyRef, ReactNode, RefObject } from "react";

import { TextArea } from "./TextInput.styled";

export enum TextInputState {
  Success,
  Error,
  Default,
}

export enum TextInputType {
  Text,
  TextArea,
}

interface CustomInputProps {
  onChange?: (e: string) => void;
  inputType?: TextInputType.Text;
}

interface CustomTextAreaProps {
  onChange?: (e: string) => void;
  inputType?: TextInputType.TextArea;
}

export interface CustomTextInputProps {
  placeholder?: string;
  inputType?: TextInputType;
  state?: TextInputState;
  isErrorState?: boolean;
  errors?: string[];
  icon?: string;
  children?: ReactNode | string;
  showSuggestions?: boolean | undefined;
  suggestions?: ReactNode | ReactNode[] | undefined;

  [key: string]: any;
}

type ITextInput = CustomTextInputProps &
  (CustomInputProps | CustomTextAreaProps);

export default ITextInput;

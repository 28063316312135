import { ReactNode } from "react";
import ValueState from "@ui5/webcomponents-base/dist/types/ValueState.js";

interface SelectOptionProps {
  key: string | number;
  text: string;
  rText?: string;
  valueState?: ValueState;
  icon?: string;
}

export default class SelectOption {
  public key: string | number;
  public icon?: string;
  public text: string;
  public rText: string = "";
  public valueState: ValueState = ValueState.None;

  constructor(props: SelectOptionProps) {
    this.key = props.key;
    this.text = props.text;
    this.icon = props.icon;
    if (props.rText) this.rText = props.rText;
    if (props.valueState) this.valueState = props.valueState;
  }
}

import { Button, ButtonType } from "../../../styles/Button.styled";
import { Field, Form } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";

import Bar from "../../Bar/Bar";
import { BarType } from "../../Bar/IBar";
import { EmailRememberProps } from "./IEmailRemember";
import HalfModal from "../../HalfModal/HalfModal";
import Text from "../../Text/Text";
import TextInput from "../../TextInput";
import { getText } from "../../../locales/initI18n";
import { useTheme } from "styled-components";
import { validateEmail } from "../../../utils/ValidationHelper";

const EmailRemember: React.FC<EmailRememberProps> = ({
  isVisible,
  onConfirm,
  onClose,
}) => {
  const theme = useTheme();
  const onSubmit = (data: { email: string }) => {
    onConfirm?.(data);
  };
  return (
    <HalfModal
      visible={isVisible}
      onClose={onClose}
      heightDivider={1.5}
      isExpander={false}
    >
      <Form onSubmit={onSubmit}>
        {({ submit, value }) => (
          <VBox Padding="20px" gap="10px">
            <Text
              type="h4"
              size="large"
              weight="bold"
              Margin="0 0 10px 0"
              color={theme.palette.Grey100}
            >
              {getText("RememberPassword")}
            </Text>
            <Field<string> name="email" onSubmitValidate={validateEmail()}>
              {({ value, setValue, onBlur, isValid, errors }) => (
                <HBox width="100%" gap="10px">
                  <TextInput
                    width="400px"
                    isErrorState={!isValid}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => setValue(e)}
                    placeholder={getText("EmailRemember.EmailPlaceholder")}
                    errors={errors}
                  >
                    {getText("EmailRemember.EmailLabel")}
                  </TextInput>
                </HBox>
              )}
            </Field>
            <Bar type={BarType.Info}>{getText("EmailRemember.InfoBar")}</Bar>
            <Button
              buttonType={ButtonType.Primary}
              padding="0 20px"
              onClick={submit}
            >
              {getText("RememberPassword")}
            </Button>
          </VBox>
        )}
      </Form>
    </HalfModal>
  );
};

export default EmailRemember;

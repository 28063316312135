import {
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";

import PageOptions from "../models/PageOptions";
import { PigeonTabEnum } from "../models/enums/PigeonTabEnum";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";
import usePigeonAssignments from "../hooks/usePigeonAssignments";

export interface PigeonSeasonGroupTableProps {
  choosenSeason?: null | number;
  type: PigeonTabEnum;
  interactiveRow?: boolean;
}

const PigeonSeasonGroupTable = ({
  choosenSeason,
  type,
  interactiveRow,
}: PigeonSeasonGroupTableProps) => {
  const navigate = useNavigate();
  const { assignments, lastMeta, fetchNextPage } = usePigeonAssignments(
    new PageOptions({
      filters: `season.year==${choosenSeason} && group=='${type}'`,
    })
  );

  return (
    <Table
      overflowMode="Popin"
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.RingNumber")}</TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeons/${choosenSeason}/${key}`);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {assignments?.map((assignment) => (
        <TableRow
          interactive={interactiveRow}
          rowKey={assignment.pigeonId.toString()}
          id={assignment.id.toString()}
          key={assignment.id}
        >
          <TableCell>{assignment.pigeon.name}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default PigeonSeasonGroupTable;

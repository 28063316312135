import {
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";

import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import TableOverflowMode from "@ui5/webcomponents/dist/types/TableOverflowMode";
import { getText } from "../locales/initI18n";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import usePigeons from "../hooks/usePigeons";

interface PigeonChildrenTableProps {
  pigeonId: number | string;
}
const PigeonChildrenTable = ({ pigeonId }: PigeonChildrenTableProps) => {
  const navigate = useNavigate();
  const { pigeons, lastMeta, fetchNextPage } = usePigeons(
    new PageOptions({
      filters: `motherId==${pigeonId} || fatherId==${pigeonId}`,
    })
  );

  return (
    <Table
      overflowMode={TableOverflowMode.Popin}
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.RingNumber")}</TableHeaderCell>
          <TableHeaderCell width="100px">
            {getText("General.Year")}
          </TableHeaderCell>
          <TableHeaderCell width="80px">
            {getText("General.Sex")}
          </TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeon/${key}`);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {pigeons?.map((pigeon) => (
        <TableRow
          interactive
          rowKey={pigeon.id.toString()}
          id={pigeon.id.toString()}
          key={`pigeons-children-table-${pigeon.id}`}
        >
          <TableCell>{pigeon.RingNumber}</TableCell>
          <TableCell>{pigeon.year}</TableCell>
          <TableCell>{Formatter.PigeonSex(pigeon.sex)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default memo(PigeonChildrenTable);

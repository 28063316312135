import {
  Button,
  Card,
  CardHeader,
  FlexBox,
  List,
  ListItemStandard,
  MessageBoxAction,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";
import { getForPigeonAndYear, removePair } from "../services/PigeonPairService";

import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import PigeonPair from "../models/PigeonPair";
import { PigeonSexEnum } from "../models/enums/PigeonSexEnum";
import TableOverflowMode from "@ui5/webcomponents/dist/types/TableOverflowMode";
import WithConfirmDialogProps from "../hoc/withConfirmDialog/WithConfirmDialogProps";
import { getAssignmentByYear } from "../services/PigeonSeasonAssignmentService";
import { getText } from "../locales/initI18n";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import usePigeonPairs from "../hooks/usePigeonPairs";
import withConfirmDialog from "../hoc/withConfirmDialog/withConfirmDialog";

export interface PigeonPairCardProps {
  slot?: string;
  year?: number | string;
  pigeon: Pigeon;
}
const PigeonPairCard = ({
  year,
  pigeon,
  slot,
  openMessage,
}: PigeonPairCardProps & WithConfirmDialogProps) => {
  const { pigeonPairs, lastMeta, fetchNextPage, invalidate } = usePigeonPairs(
    new PageOptions({
      filters: !year
        ? `male.id==${pigeon.id} || female.id==${pigeon.id}`
        : `(male.id==${pigeon.id} && season.year==${year}) || (female.id==${pigeon.id} && season.year==${year})`,
    })
  );
  const dispatch = useAppDispatch();

  const onDelete = async (pair: PigeonPair) => {
    const confirm = await openMessage(
      `Czy na pewno chcesz usunąć parę: ${pair.maleRingNumber} - ${pair.femaleRingNumber}? Para zostanie usunięta natychmiast po zaakceptowaniu.`
    );
    if (confirm !== MessageBoxAction.OK) return;

    try {
      await removePair(pair.id);
      invalidate();
    } catch (e) {
      dispatch(setError(e));
    }
  };
  return (
    <Table
      overflowMode={TableOverflowMode.Popin}
      slot={slot}
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>Sezon</TableHeaderCell>
          <TableHeaderCell minWidth="200px">
            {pigeon.sex === PigeonSexEnum.Female ? "Samiec" : "Samica"}
          </TableHeaderCell>
          <TableHeaderCell>Lęg</TableHeaderCell>
          <TableHeaderCell width="200px"></TableHeaderCell>
        </TableHeaderRow>,
      ]}
    >
      {lastMeta?.isNext && !year && (
        <TableGrowing
          type="Button"
          growing-text="More"
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {pigeonPairs.map((pair) => {
        return (
          <TableRow key={`PigeonPairCard-${pair.id}`}>
            <TableCell>{pair.season.year}</TableCell>
            <TableCell>
              {pigeon.sex === PigeonSexEnum.Female
                ? pair.maleRingNumber
                : pair.femaleRingNumber}
            </TableCell>
            <TableCell>
              <Button
                onClick={() => {
                  onDelete(pair);
                }}
                icon="delete"
                design={ButtonDesign.Negative}
              >
                Usuń parę
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default withConfirmDialog(PigeonPairCard);

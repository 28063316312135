import { HBox, VBox } from "../../styles/Flexbox.styled";
import {
  Label,
  Option,
  SelectDomRef,
  Select as UISelect,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import { memo, useEffect, useRef, useState } from "react";

import { SelectChangeEventDetail } from "@ui5/webcomponents/dist/Select";
import SelectOption from "../../models/SelectOption";
import { SelectProps } from "./ISelect";
import { useTheme } from "styled-components";

const Select = ({
  options,
  value,
  onSelect,
  fixedHeight,
  disabled,
  children,
}: SelectProps) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState<SelectOption>();

  const onChangeOption = (
    event: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>
  ) => {
    const key = event.detail.selectedOption.dataset.id;
    const option = options.find((o) => o.key.toString() === key);
    if (!option) return;
    onSelect(option);
    setSelectedValue(option);
  };

  useEffect(() => {
    if (typeof value === "string" || typeof value === "number") {
      const option = options.find((o) => o.key === value);
      if (option) setSelectedValue(option);
    } else setSelectedValue(value);
  }, [value]);

  return (
    <VBox ref={containerRef} position="relative" flexGrow="1">
      {children && <Label>{children}</Label>}
      <UISelect
        style={{ width: "100%" }}
        disabled={disabled}
        valueState={selectedValue?.valueState}
        onChange={onChangeOption}
      >
        {options.map((option) => (
          <Option
            selected={option.key === selectedValue?.key}
            key={option.key}
            data-id={option.key}
            icon={option.icon}
            additionalText={option.rText}
          >
            {option.text}
          </Option>
        ))}
      </UISelect>
    </VBox>
  );
};

export default memo(Select, (prev, next) => {
  return prev.value === next.value && prev.disabled === next.disabled;
});

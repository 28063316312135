import { MessageBoxAction } from "@ui5/webcomponents-react";

export default class DialogResult<T = unknown> {
  private _action: MessageBoxAction;
  private _data!: T;

  static Close() {
    return new DialogResult(MessageBoxAction.Close);
  }

  constructor(action: MessageBoxAction, data?: T) {
    this._action = action;
    if (data) this._data = data;
  }

  public getData<TData = T>(): TData {
    return this._data as unknown as TData;
  }

  public isClose(): boolean {
    return this._action === MessageBoxAction.Close;
  }

  public isOk(): boolean {
    return this._action === MessageBoxAction.OK;
  }
}

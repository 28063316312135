import {
  Button,
  Form,
  FormGroup,
  FormItem,
  Label,
} from "@ui5/webcomponents-react";

import DateInput from "../DateInput/DateInput";
import Egg from "../../models/Egg";
import { EggState } from "../../models/enums/EggStateEnum";
import HalfModal from "../HalfModal/HalfModal";
import { ModalProps } from "./IModalOptions";
import Select from "../Select/Select";
import SelectOption from "../../models/SelectOption";
import TextInput from "../TextInput";
import { getTag } from "../../services/TagService";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import { useState } from "react";

const options = Object.entries(EggState).map(
  (key) => new SelectOption({ key: key[1], text: key[0] })
);

const AddEgg = (props: ModalProps<Egg[], undefined>) => {
  const [dateOfLaying, setDateOfLaying] = useState<Date>();
  const [status, setStatus] = useState<EggState>(EggState.Fertile);
  const [numberOfEggs, setNumberOfEggs] = useState<number>(1);
  const [brood, setBrood] = useState<number>(1);
  const dispatch = useAppDispatch();

  const onAdd = () => {
    try {
      if (Number(numberOfEggs) < 1) {
        throw new Error("Ilość jajek musi być większa od 0");
      }
      const eggs = Array.from({ length: numberOfEggs }, (_, i) => {
        return Egg.create({
          dateOfLaying: dateOfLaying,
          status: status,
          brood,
        });
      });
      props.onConfirm(eggs);
    } catch (e) {
      dispatch(setError(e));
    }
  };

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      title={getText("AddEgg.Title")}
      description={getText("AddEgg.Description")}
      isExpander={false}
      heightDivider={1.4}
    >
      <Form>
        <FormGroup>
          <FormItem
            labelContent={<Label>{getText("AddEgg.DateOfLaying")}</Label>}
          >
            <DateInput
              value={dateOfLaying}
              onChange={(value) => setDateOfLaying(value)}
            />
          </FormItem>
          <FormItem labelContent={<Label>{getText("AddEgg.Status")}</Label>}>
            <Select
              value={status}
              onSelect={(option) => setStatus(option.key as EggState)}
              options={options}
            />
          </FormItem>
          <FormItem
            labelContent={<Label>{getText("AddEgg.NumberOfEggs")}</Label>}
          >
            <TextInput
              type="number"
              value={numberOfEggs}
              onChange={(value) => setNumberOfEggs(Number(value))}
            />
          </FormItem>
          <FormItem labelContent={<Label>{getText("AddEgg.Brood")}</Label>}>
            <Select
              value={brood}
              onSelect={(option) => setBrood(option.key as number)}
              options={[
                new SelectOption({ key: 1, text: "1" }),
                new SelectOption({ key: 2, text: "2" }),
                new SelectOption({ key: 3, text: "3" }),
                new SelectOption({ key: 4, text: "4" }),
                new SelectOption({ key: 5, text: "5" }),
              ]}
            />
          </FormItem>
          <FormItem>
            <Button onClick={onAdd} design="Emphasized">
              {getText("AddEgg.AddEggs")}
            </Button>
          </FormItem>
        </FormGroup>
      </Form>
    </HalfModal>
  );
};

export default AddEgg;

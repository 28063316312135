import { RefObject, memo } from "react";
import {
  Switch,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableSelection,
  TableSelectionDomRef,
} from "@ui5/webcomponents-react";

import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import { getNotSelectedPigeons } from "../services/PigeonSeasonSelectionService";
import { getText } from "../locales/initI18n";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export interface PigeonNotSelectedToSeasonTableProps {
  selection?: RefObject<TableSelectionDomRef>;
  year: number;
}

const PigeonNotSelectedToSeasonTable = ({
  selection,
  year,
}: PigeonNotSelectedToSeasonTableProps) => {
  const navigate = useNavigate();
  const queryFlights = useInfiniteQuery({
    queryKey: [
      "pigeons",
      "pigeon-season-selection",
      "pigeon-season-selection-not-selected",
      { filters: `year==${year}` },
    ],
    queryFn: ({ pageParam = 1 }) =>
      getNotSelectedPigeons(year || 2024, new PageOptions({ page: pageParam })),
    getNextPageParam: new PageOptions().nextPagefn,
  });

  const pigeons = queryFlights.data?.pages.flatMap((page) => page.data) || [];
  const lastMeta =
    queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta;

  return (
    <Table
      overflowMode="Popin"
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.RingNumber")}</TableHeaderCell>
          <TableHeaderCell width="80px">
            {getText("General.Sex")}
          </TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeon/${key}`);
      }}
    >
      <TableSelection
        ref={selection}
        mode="Multiple"
        slot="features"
      ></TableSelection>
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => queryFlights.fetchNextPage()}
        ></TableGrowing>
      )}
      {pigeons?.map((pigeon) => (
        <TableRow
          interactive
          rowKey={pigeon.id.toString()}
          id={pigeon.id.toString()}
          key={`pigeons-view-${pigeon.id}`}
        >
          <TableCell>{pigeon.RingNumber}</TableCell>
          <TableCell>{Formatter.PigeonSex(pigeon.sex)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default memo(PigeonNotSelectedToSeasonTable, (prev, next) => {
  return prev.year === next.year;
});

import {
  Button,
  MessageBoxAction,
  ObjectStatus,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";
import { deleteEgg, hatchEgg } from "../services/EggService";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

import AddEgg from "./modals/AddEgg";
import BaseDialogProps from "../models/BaseDialogProps";
import DialogResult from "../models/DialogResult";
import Egg from "../models/Egg";
import { EggState } from "../models/enums/EggStateEnum";
import Formatter from "../utils/Formatter";
import HatchEgg from "./modals/HatchEgg";
import PageOptions from "../models/PageOptions";
import WithConfirmDialogProps from "../hoc/withConfirmDialog/WithConfirmDialogProps";
import { getText } from "../locales/initI18n";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import useEggs from "../hooks/useEggs";
import { useNavigate } from "react-router-dom";
import withConfirmDialog from "../hoc/withConfirmDialog/withConfirmDialog";

export interface PigeonSeasonEggTableProps {
  choosenSeason?: null | number;
  interactiveRow?: boolean;
}

const PigeonSeasonEggTable = ({
  choosenSeason,
  interactiveRow,
  openDialog,
  openMessage,
}: PigeonSeasonEggTableProps & WithConfirmDialogProps & WithDialogsProps) => {
  const navigate = useNavigate();
  const { eggs, fetchNextPage, lastMeta, invalidate } = useEggs(
    new PageOptions({ filters: `pigeonPair.season.year==${choosenSeason}` })
  );
  const dispatch = useAppDispatch();
  const navigateToPigeon = (id: number) => {
    navigate(`/dashboard/pigeons/${choosenSeason}/${id}`);
  };
  const onDeleteEgg = async (egg: Egg) => {
    const confirm = await openMessage("Czy na pewno chcesz usunąć jajko?");
    if (confirm !== MessageBoxAction.OK) return;

    try {
      await deleteEgg(egg.id);
      invalidate();
    } catch (e) {
      dispatch(setError(e));
    }
  };
  const onHatchEgg = async (egg: Egg) => {
    const result = await openDialog("HatchEgg", { egg });
    if (result.isClose()) return;

    const hatchDate = result.getData<{ hatchDate: Date }>().hatchDate;
    try {
      await hatchEgg(egg, hatchDate);
      invalidate();
    } catch (e) {
      dispatch(setError(e));
    }
  };
  return (
    <Table
      overflowMode="Popin"
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.Male")}</TableHeaderCell>
          <TableHeaderCell>{getText("General.Female")}</TableHeaderCell>
          <TableHeaderCell>{getText("General.LayingDate")}</TableHeaderCell>
          <TableHeaderCell>{getText("AddEgg.Status")}</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeons/${choosenSeason}/${key}`);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {eggs?.map((egg) => (
        <TableRow
          interactive={interactiveRow}
          rowKey={egg.id.toString()}
          id={egg.id.toString()}
          key={`PigeonSeasonEggTable-${egg.id}`}
        >
          <TableCell>
            <ObjectStatus
              onClick={() => navigateToPigeon(egg.pigeonPair.male.id)}
              interactive
              inverted
            >
              {egg.pigeonPair.maleRingNumber}
            </ObjectStatus>
          </TableCell>
          <TableCell>
            <ObjectStatus
              onClick={() => navigateToPigeon(egg.pigeonPair.female.id)}
              interactive
              inverted
            >
              {egg.pigeonPair.femaleRingNumber}
            </ObjectStatus>
          </TableCell>
          <TableCell>{Formatter.DateTime(egg.dateOfLaying)}</TableCell>
          <TableCell>{egg.status}</TableCell>
          <TableCell>
            {egg.status === EggState.Fertile && (
              <Button onClick={() => onHatchEgg(egg)}>Wykluj</Button>
            )}
          </TableCell>
          <TableCell>
            {egg.status === EggState.Fertile && (
              <Button
                design="Negative"
                icon="delete"
                onClick={() => onDeleteEgg(egg)}
              ></Button>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "HatchEgg":
      const egg = (props as { egg: Egg }).egg;
      return (
        <HatchEgg
          {...props}
          egg={egg}
          isVisible
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
          onClose={(value) => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    default:
      return null;
  }
};

export default withConfirmDialog(
  withDialogs(PigeonSeasonEggTable, renderDialogs)
);

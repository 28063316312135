import { HBox } from "../../styles/Flexbox.styled";
import { HighligtedTextProps } from "./IHighlightedText";
import Text from "../Text/Text";
import { useTheme } from "styled-components";

const HighligtedText = ({
  text,
  backgroundColor,
  textColor,
  visible,
}: HighligtedTextProps) => {
  const theme = useTheme();
  return (
    <HBox
      backgroundColor={
        backgroundColor ? backgroundColor : theme.palette.Primary100
      }
      visible={visible}
      alignItems="center"
      justifyContent="center"
      height="23px"
      Padding="0 10px"
      BorderRadius="8px"
    >
      <Text
        type="body"
        size="small"
        weight="bold"
        color={textColor ? textColor : theme.palette.White}
        Margin="0"
        Padding="0"
        fontStyle="italic"
        dots={true}
      >
        {text}
      </Text>
    </HBox>
  );
};

export default HighligtedText;

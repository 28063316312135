import "reactflow/dist/style.css";

import { Button, ButtonType } from "../../styles/Button.styled";
import { EmptyNodeData, PigeonNodeAction, PigeonNodeData } from "./NodeProps";
import { HBox, VBox } from "../../styles/Flexbox.styled";
import { IoChevronForward, IoInformationCircleOutline } from "react-icons/io5";
import { MouseEvent, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  Node,
  NodeChange,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { getPedigree, updatePigeon } from "../../services/PigeonService";
import { useParams, useSearchParams } from "react-router-dom";
import withDialogs, {
  WithDialogsProps,
} from "../../hoc/withDialogs/withDialogs";

import BaseDialogProps from "../../models/BaseDialogProps";
import ChooseFather from "../../components/modals/ChoosePigeon/ChooseFather";
import ChooseMother from "../../components/modals/ChoosePigeon/ChooseMother";
import DialogResult from "../../models/DialogResult";
import EmptyNode from "./EmptyNode";
import GraphData from "../../models/GraphData";
import { MessageBoxAction } from "@ui5/webcomponents-react";
import PedigreeInformaion from "../../components/PedigreeInformation";
import Pigeon from "../../models/Pigeon";
import PigeonNode from "./PigeonNode";
import PigeonSelectDialog from "../modals/PigeonSelectDialog";
import { PigeonSexEnum } from "../../models/enums/PigeonSexEnum";
import Text from "../../components/Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const nodeTypes = {
  PigeonNode: PigeonNode,
  EmptyNode: EmptyNode,
};

interface PigeonGraphProps {
  pigeon: Pigeon;
}

const PigeonGraph = ({
  pigeon,
  openDialog,
}: PigeonGraphProps & WithDialogsProps) => {
  const theme = useTheme();
  const reactFlow = useReactFlow();
  const graphData = useRef<GraphData>();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const isInitlized = useRef(false);
  const [maxLevel, setMaxLevel] = useState(3);
  const [showGenerateIncoming, setShowGenerateIncoming] = useState(true);

  const getGraphData = () => {
    if (!graphData.current) {
      graphData.current = new GraphData();
    }
    graphData.current.setActionCallback(onNodeAction);
    return graphData.current;
  };

  const onNodeAction = (type: PigeonNodeAction, pigeon: Pigeon) => {
    switch (type) {
      case PigeonNodeAction.Show:
        break;
      case PigeonNodeAction.Edit:
        break;
      case PigeonNodeAction.Remove:
        break;
    }
    void buildGraph(pigeon.id);
  };

  const buildGraph = async (id?: number) => {
    const pedigree = await getPedigree(id || pigeon.id, maxLevel);

    const graphData = getGraphData();
    graphData.init(pedigree, maxLevel);

    const { nodes, edges } = await graphData.build();

    setNodes(nodes);
    setEdges(edges);
  };

  const onNodeClick = async (
    event: MouseEvent,
    node: Node<PigeonNodeData | EmptyNodeData>
  ) => {
    if (node.type === "EmptyNode") {
      const data = node.data as EmptyNodeData;
      const sex = data.isFather ? PigeonSexEnum.Male : PigeonSexEnum.Female;
      const result = await openDialog("PigeonSelectDialog", {
        initFilters: `sex == ${sex}`,
      });
      if (result.isClose()) return;
      const parent = result.getData<Pigeon>();
      setParent(data.pigeon, parent);
    }
  };

  const onChangeNodes = (nodes: NodeChange[]) => {
    if (reactFlow.getNodes().length !== 0 && isInitlized.current === false) {
      isInitlized.current = true;
      reactFlow.fitView({ padding: 0.1 });
    }
  };
  const setParent = async (parentChooseFor: Pigeon, parent: Pigeon) => {
    if (parentChooseFor.sex === PigeonSexEnum.Male) {
      parentChooseFor.father = parent;
    } else if (parentChooseFor.sex === PigeonSexEnum.Female) {
      parentChooseFor.mother = parent;
    } else throw new Error("Parent does not have sex defined");

    await updatePigeon(parentChooseFor);
    void buildGraph();
  };

  useEffect(() => {
    setTimeout(() => {
      setShowGenerateIncoming(false);
    }, 3000);
    void buildGraph();
  }, [pigeon.id]);

  return (
    <VBox height="100%" gap="10px" position="relative">
      <HBox
        position="absolute"
        Top="5px"
        Right="5px"
        zIndex={1}
        BorderRadius="20px"
        overflowX="hidden"
        overflowY="hidden"
      >
        <Button
          onClick={() => openDialog("PedigreeInformaion")}
          padding="0 40px 0 10px"
          height="30px"
          isCustom={true}
          buttonType={ButtonType.Primary}
          style={{
            transform: !showGenerateIncoming
              ? "translateX(400px)"
              : "translateX(30px)",
          }}
        >
          <HBox alignItems="center">
            <Text
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              type={TextTypeEnum.body}
              textWrap="nowrap"
              Margin="0 5px 0 0"
            >
              {getText("Dna.view.PedigreeInformation.KnowMore")}
            </Text>
            <IoChevronForward size={15} />
          </HBox>
        </Button>
        <Button
          onClick={() => openDialog("PedigreeInformaion")}
          height="30px"
          isCustom={true}
          buttonType={ButtonType.Primary}
        >
          <HBox gap="5px">
            <IoInformationCircleOutline size={20} />
          </HBox>
        </Button>
      </HBox>
      <ReactFlow
        edges={edges}
        minZoom={0.1}
        maxZoom={1.5}
        zoomOnScroll
        nodes={nodes}
        onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
        style={{ height: "100%" }}
        onNodesChange={onChangeNodes}
        onPaneClick={() => {
          setNodes((nodes) => nodes.map((n) => ({ ...n, selected: false })));
        }}
      >
        <Background color={theme.palette.Grey50} gap={16} />
        <Controls />
      </ReactFlow>
    </VBox>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "PigeonSelectDialog":
      return (
        <PigeonSelectDialog
          {...props}
          isVisible
          onClose={(value) =>
            onClose(
              new DialogResult(
                value ? MessageBoxAction.OK : MessageBoxAction.Close,
                value
              )
            )
          }
        />
      );
    case "PedigreeInformaion":
      return (
        <PedigreeInformaion
          isVisible
          onClose={() => onClose(DialogResult.Close())}
          onConfirm={() => onClose(DialogResult.Close())}
        />
      );
    default:
      return null;
  }
};

export default withDialogs(PigeonGraph, renderDialogs);

import "@maptiler/sdk/dist/maptiler-sdk.css";

import { Button, ButtonType } from "../styles/Button.styled";
import { FlexibleColumnLayout, Icon } from "@ui5/webcomponents-react";
import { FlightMarker, Marker } from "../models/Marker";
import { HBox, VBox } from "../styles/Flexbox.styled";
import {
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonOpacity } from "../styles/ButtonOpacity.styled";
import CookieHelper from "../utils/CookieHelper";
import DistrictChecker from "../components/modals/DistrictChecker/DistrictChecker";
import FCLLayout from "@ui5/webcomponents-fiori/dist/types/FCLLayout";
import { Flight } from "../models/Flight";
import FlightHelper from "../utils/FlightHelper";
import Layers from "../components/Layers/Layers";
import MapFlightBuilder from "../utils/MapFlightBuilder";
import { MapLayerEnum } from "../models/enums/MapLayerEnum";
import MapLayerLegend from "../components/MapLayerLegend/MapLayerLegend";
import { MapModal } from "../models/enums/MapModal";
import Text from "../components/Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import TimeSlider from "../components/TimeSlider";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { getWeather } from "../services/WeatherService";
import useCotes from "../hooks/useCotes";
import useFlight from "../hooks/useFlight";
import { useQueryClient } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import withNavbar from "../hoc/withNavbar/withNavbar";

const FlightMarkerInformation = lazy(
  () =>
    import(
      "../components/modals/FlightMarkerInformation/ FlightMarkerInformation"
    )
);
const MarkerInformation = lazy(
  () => import("../components/modals/MarkerInformation/MarkerInformation")
);
const FlightSummaryPage = lazy(() => import("./FlightSummaryPage"));

const PigeonMap = () => {
  const mapID = "PigeonMap__animation-map";
  const { flightId } = useParams();
  const mapBuilder = useRef<MapFlightBuilder>();
  const theme = useTheme();
  const [mapModal, setMapModal] = useState<MapModal>(MapModal.NONE);
  const [visibleFlight, setVisibleFlight] = useState<Flight | null>(null);
  const [mapMarker, setMapMarker] = useState<Marker | null>(null);
  const [currentLayer, setCurrentLayer] = useState<MapLayerEnum>(
    MapLayerEnum.Wind
  );
  const [layout, setLayout] = useState<FCLLayout>(FCLLayout.OneColumn);
  const [flightMarker, setFlightMarker] = useState<FlightMarker | null>(null);
  const mapTime = useRef<Date>(new Date());
  const queryClient = useQueryClient();
  const { data: cotes } = useCotes();
  const { get } = useFlight(true);
  const navigate = useNavigate();

  const createBuilder = () => {
    mapBuilder.current = new MapFlightBuilder(
      mapID,
      onPressMap,
      getMarkerWeather
    );
  };

  useEffect(() => {
    createBuilder();
  }, []);

  useEffect(() => {
    getMapFlight();
  }, [flightId]);

  useEffect(() => {
    if (cotes?.data) {
      mapBuilder.current?.removeTags();
      mapBuilder.current?.addTags(cotes.data, onPressMap);
    }
  }, [cotes?.data]);

  const getMapFlight = useCallback(async () => {
    const fetchedFlight = await get(Number(flightId));
    if (!fetchedFlight.canBeRender()) return;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    onConfirmShowFlight(fetchedFlight);
  }, [flightId]);

  const getMarkerWeather = async (marker: Marker) => {
    return queryClient.ensureQueryData(
      [
        "weather",
        marker.latitude,
        marker.longitude,
        mapTime.current.toISOString(),
      ],
      () => getWeather(marker, mapTime.current)
    );
  };

  const onPressMap = (marker: Marker | null) => {
    setMapMarker(marker);
  };

  const onFlightMarker = (marker: FlightMarker) => {
    setFlightMarker(marker);
  };

  const onConfirmShowFlight = useCallback((flight: Flight) => {
    if (!mapBuilder.current) return;
    setMapModal(MapModal.NONE);
    setVisibleFlight(flight);
    if (breakpoints.isPhone()) setLayout(FCLLayout.OneColumn);
    else setLayout(FCLLayout.TwoColumnsStartExpanded);
    setMapMarker(null);
    mapBuilder.current.showFlight(flight, onFlightMarker);
  }, []);

  const onCloseFlight = useCallback(() => {
    if (!visibleFlight || !mapBuilder.current) return;
    CookieHelper.flightId.remove();
    mapTime.current = new Date();
    mapBuilder.current.unsetActiveMarker();
    mapBuilder.current.removeFlight(visibleFlight.id.toString());
    mapBuilder.current.showCurrentLayer();
    mapBuilder.current.setCurrentLayerTime();
    setLayout(FCLLayout.OneColumn);
    setVisibleFlight(null);
    onAfterChangeTime(mapTime.current);
  }, [visibleFlight]);

  const hideModal = () => {
    if (!mapBuilder.current) return;
    mapBuilder.current.unsetActiveMarker();
    setMapModal(MapModal.NONE);
    setMapMarker(null);
    setFlightMarker(null);
  };

  const changeLayer = (layer: MapLayerEnum) => {
    setCurrentLayer(layer);
    mapBuilder.current?.changeLayer(layer);
  };

  const onAfterChangeTime = (time: Date | string) => {
    time = typeof time === "string" ? new Date(time) : time;
    mapTime.current = time;
    mapBuilder.current?.setLayerTime(time);
    mapBuilder.current?.refreshPopups();
  };

  const onChangeMapWeatherVisibility = (isVisible: boolean) => {
    if (isVisible) mapBuilder.current?.showPopups();
    else mapBuilder.current?.hidePopups();
  };

  const getShowWeatherOnMap = () => {
    return mapBuilder.current?.getShowWeatherPopups?.() || false;
  };

  const returnToMap = () => {
    setLayout(FCLLayout.OneColumn);
  };

  const showSummary = () => {
    setLayout(FCLLayout.TwoColumnsStartExpanded);
  };

  const goToFlights = async () => {
    navigate("/dashboard/list/flights");
  };

  return (
    <VBox
      position="relative"
      width="100%"
      height="100%"
      zIndex={1}
      border={!!visibleFlight ? `4px solid ${theme.palette.Primary100}` : ""}
    >
      <DistrictChecker />
      <Suspense>
        <MarkerInformation
          isVisible={!!mapMarker}
          marker={mapMarker}
          onClose={hideModal}
          onConfirm={() => {}}
          isMapWeatherVisible={getShowWeatherOnMap()}
          onChangeMapWeatherVisibility={onChangeMapWeatherVisibility}
          mapTime={mapTime.current}
        />
        <FlightMarkerInformation
          flight={visibleFlight}
          marker={flightMarker}
          isVisible={!!flightMarker}
          onClose={() => hideModal()}
          onConfirm={() => {}}
          isMapWeatherVisible={getShowWeatherOnMap()}
          onChangeMapWeatherVisibility={onChangeMapWeatherVisibility}
        />
      </Suspense>
      <FlexibleColumnLayout
        style={{ width: "100%", height: "calc(100% - 50px)" }}
        startColumn={
          <VBox className="" position="relative" width="100%" height="100%">
            <Layers
              initLayer={currentLayer}
              onChangeLayer={changeLayer}
              visible={FlightHelper.isWeatherLayer(visibleFlight)}
            />
            <MapLayerLegend
              layer={currentLayer}
              visible={FlightHelper.isWeatherLayer(visibleFlight)}
            />
            <HBox
              position="absolute"
              Right="80px"
              width="200px"
              height="30px"
              backgroundColor={theme.palette.Primary80}
              BorderRadius="0 0 10px 10px"
              zIndex={1}
              visible={!!visibleFlight && breakpoints.isPhone()}
            >
              <ButtonOpacity onClick={showSummary}>
                <Text
                  type={TextTypeEnum.body}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.medium}
                  color={theme.palette.White}
                >
                  Wróć do podsumowania
                </Text>
              </ButtonOpacity>
            </HBox>
            <div id={mapID} style={{ width: "100%", height: "100%" }} />
          </VBox>
        }
        layout={layout}
        midColumn={
          visibleFlight && (
            <Suspense>
              <FlightSummaryPage
                returnToMap={returnToMap}
                slot="midColumn"
                flight={visibleFlight}
                goBack={() => onCloseFlight()}
              />
            </Suspense>
          )
        }
      />
      <HBox
        position="absolute"
        visible={breakpoints.isPhone()}
        Bottom="60px"
        Right="10px"
        Padding="4px"
        backgroundColor={theme.palette.White}
        justifyContent="center"
        alignItems="center"
        BorderRadius="20px"
      >
        <Button
          isCustom={true}
          buttonType={ButtonType.Primary}
          height="auto"
          padding="15px"
          onClick={goToFlights}
        >
          <HBox alignItems="center" gap="10px">
            <Icon name="map-fill" design="Contrast" />
            <Text
              type="body"
              size="medium"
              weight="bold"
              color={theme.palette.White}
            >
              {getText("Map.OptionFlights")}
            </Text>
          </HBox>
        </Button>
      </HBox>
      <VBox
        position="absolute"
        Bottom="0px"
        Right="0px"
        backgroundColor={theme.palette.White}
        justifyContent="center"
        width="100%"
        Padding="5px"
        zIndex={2}
      >
        <TimeSlider
          onAfterChange={onAfterChangeTime}
          time={mapTime.current}
          changeTime={() => {}}
        />
      </VBox>
    </VBox>
  );
};

export default memo(withNavbar(PigeonMap));

import { Base } from "./Base";
import { Flight } from "./Flight";
import { FlightSharePermisionEnum } from "./enums/FlightSharePermisionEnum";
import { IUser } from "../services/IUserService";
import User from "./User";

export class FlightSharing extends Base {
  flightId!: number;
  user = new User();
  userId!: number;
  startDate!: Date;
  duration!: number;
  permision!: FlightSharePermisionEnum;

  fillData(data: object): this {
    super.fillData(data);
    this.user = User.create(this.user);
    this.startDate = new Date(this.startDate);
    return this;
  }
}

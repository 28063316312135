import { HBox, VBox } from "../../styles/Flexbox.styled";

import Formatter from "../../utils/Formatter";
import { MapLayerEnum } from "../../models/enums/MapLayerEnum";
import { MapLayerLegendProps } from "./IMapLayerLegend";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";

const MapLayerLegend = ({ layer, visible = true }: MapLayerLegendProps) => {
  const theme = useTheme();
  if (!visible) return null;
  if (layer === MapLayerEnum.Wind) {
    return (
      <VBox
        position="absolute"
        Top="0"
        Left="0"
        width="60px"
        height="180px"
        backgroundColor={theme.palette.White}
        BorderRadius="0 0 5px 0"
        zIndex={1}
      >
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(68, 1, 84)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(0, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(71, 44, 122)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(10, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(59, 81, 139)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(20, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(44, 113, 142)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(30, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(33, 144, 141)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(35, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(39, 173, 129)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(45, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(92, 200, 99)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(55, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(170, 220, 50)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(65, false)}
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox backgroundColor="rgb(253, 231, 37)" width="5px" height="20px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            {Formatter.WindSpeed(75, false)}
          </Text>
        </HBox>
      </VBox>
    );
  }
  if (layer === MapLayerEnum.Temperature) {
    return (
      <VBox
        position="absolute"
        Top="0"
        Left="0"
        width="42px"
        height="120px"
        backgroundColor={theme.palette.White}
        BorderRadius="0 0 5px 0"
        zIndex={1}
      >
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(115, 70, 105)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -70°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox
            backgroundColor="rgb(202, 172, 195)"
            width="5px"
            height="10px"
          />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -55°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(162, 70, 145)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -40°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(143, 89, 169)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -25°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox
            backgroundColor="rgb(157, 219, 217)"
            width="5px"
            height="10px"
          />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -15°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox
            backgroundColor="rgb(106, 191, 181)"
            width="5px"
            height="10px"
          />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -8°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox
            backgroundColor="rgb(100, 166, 189)"
            width="5px"
            height="10px"
          />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            -4°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(68, 125, 99)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            0°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(128, 147, 24)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            10°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(243, 183, 4)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            20°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(232, 83, 25)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            30°C
          </Text>
        </HBox>
        <HBox gap="5px" alignItems="center" height="10px">
          <HBox backgroundColor="rgb(71, 14, 0)" width="5px" height="10px" />
          <Text
            type={TextTypeEnum.footer}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey60}
          >
            45°C
          </Text>
        </HBox>
      </VBox>
    );
  }
  return null;
};

export default MapLayerLegend;

import { HBox, VBox } from "../styles/Flexbox.styled";
import {
  IoBuildOutline,
  IoCreateOutline,
  IoEllipsisVerticalOutline,
  IoNewspaperOutline,
  IoWarningOutline,
} from "react-icons/io5";
import {
  editUserData,
  requestPlanner,
} from "../services/UserService";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Bar from "../components/Bar/Bar";
import { BarType } from "../components/Bar/IBar";
import ChangePassword from "../components/modals/ChangePassword/ChangePassword";
import { ChangePasswordConfirmProps } from "../components/modals/ChangePassword/IChangePassword";
import Confirm from "../components/modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../models/enums/ConfirmModalTypeEnum";
import EditProfile from "../components/modals/EditProfile/EditProfile";
import HighligtedText from "../components/HighlightedText/HighlightedText";
import { IEditProfile } from "../services/IUserService";
import ListItem from "../components/ListItem/ListItem";
import { ListItemAction } from "../components/ListItem/IListItem";
import Loader from "../components/Loader/loader";
import MoreProfileOptions from "../components/modals/MoreProfileOptions/MoreProfileOptions";
import { ProfileModal } from "../models/enums/ProfileModal";
import Text from "../components/Text/Text";
import { UserType } from "../models/enums/UserType";
import { changePassword } from "../services/AuthService";
import { formatDate } from "../utils/VariableHelper";
import { getText } from "../locales/initI18n";
import { resetAuth } from "../redux/modules/auth";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import useUser from "../hooks/useUser";
import withNavbar from "../hoc/withNavbar/withNavbar";

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [profileModal, setProfileModal] = useState<ProfileModal>(
    ProfileModal.NONE
  );
  const [phoneIsRequested, setPhoneIsRequested] = useState(false);
  const { data, isLoading, refetch, isError, error } = useUser();

  useEffect(() => {
    if (isError) {
      dispatch(setError(error));
    }
  }, [isError]);

  const logout = () => {
    queryClient.removeQueries();
    dispatch(resetAuth());
    navigate("/");
  };

  const editProfile = async (data: IEditProfile) => {
    setProfileModal(ProfileModal.NONE);
    setPhoneIsRequested(false);
    try {
      await editUserData(data);
      logout();
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const onChangePassword = async (data: ChangePasswordConfirmProps) => {
    setProfileModal(ProfileModal.NONE);
    try {
      await changePassword(data.oldPassword, data.newPassword);
      logout();
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const onRequestPlanner = async () => {
    try {
      if (!user?.phoneNumber) {
        setPhoneIsRequested(true);
        setProfileModal(ProfileModal.EDIT);
        return;
      }
      await requestPlanner();
      setProfileModal(ProfileModal.NONE);
      refetch();
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const isPlannerProcessing = () => {
    if (!user) return false;
    return (
      new Date(user.plannerRequestValid) > new Date() &&
      user.type === UserType.Regular
    );
  };

  const isPlannerRequestVisible = () => {
    if (!user) return false;
    if (isPlannerProcessing()) return false;
    return !(
      user.type !== UserType.Regular &&
      (!user.plannerRequestValid ||
        new Date(user.plannerRequestValid) < new Date())
    );
  };

  if (isLoading)
    return (
      <VBox alignItems="center" justifyContent="center" height="100%">
        <Loader />
      </VBox>
    );
  const user = data;
  if (!user) return null;
  return (
    <VBox overflowY="scroll">
      {ProfileModal.NONE !== profileModal && (
        <>
          <EditProfile
            phoneIsRequired={phoneIsRequested}
            onClose={() => {
              setPhoneIsRequested(false);
              setProfileModal(ProfileModal.NONE);
            }}
            isVisible={profileModal === ProfileModal.EDIT}
            onConfirm={editProfile}
          />
          <ChangePassword
            onClose={() => setProfileModal(ProfileModal.NONE)}
            isVisible={profileModal === ProfileModal.CHANGE_PASSWORD}
            onConfirm={onChangePassword}
          />
          <Confirm
            onClose={() => setProfileModal(ProfileModal.NONE)}
            onConfirm={onRequestPlanner}
            isVisible={profileModal === ProfileModal.CONFIRM_PLANNER}
            type={ConfirmModalTypeEnum.Warn}
            title={getText("Profile.View.ConfirmationToBePlannerTitle")}
            description={getText(
              "Profile.View.ConfirmationToBePlannerDescription"
            )}
            confirmText={getText(
              "Profile.View.ConfirmationToBePlannerConfirmAction"
            )}
            cancelText={getText("Profile.View.ConfirmationToBePlannerCancel")}
          />
          <Confirm
            onClose={() => setProfileModal(ProfileModal.NONE)}
            onConfirm={logout}
            isVisible={profileModal === ProfileModal.LOGOUT}
            type={ConfirmModalTypeEnum.Warn}
            title={getText("Profile.View.ConfirmationLogoutTitle")}
            description={getText("Profile.View.ConfirmationLogoutDescription")}
            confirmText={getText(
              "Profile.View.ConfirmationLogoutConfirmAction"
            )}
            cancelText={getText("Profile.View.Return")}
          />
          <Confirm
            onClose={() => setProfileModal(ProfileModal.NONE)}
            onConfirm={() => navigate("/delete-account")}
            isVisible={profileModal === ProfileModal.DELETE_ACCOUNT}
            type={ConfirmModalTypeEnum.Delete}
            title={getText("Profile.View.ConfirmationDeleteAccountTitle")}
            description={getText(
              "Profile.View.ConfirmationDeleteAccountDescription"
            )}
            confirmText={getText(
              "Profile.View.ConfirmationDeleteAccountConfirmAction"
            )}
            cancelText={getText("Profile.View.Return")}
          />
          <MoreProfileOptions
            isVisible={profileModal === ProfileModal.MORE}
            onClose={() => setProfileModal(ProfileModal.NONE)}
            onConfirm={(modal: ProfileModal) => setProfileModal(modal)}
          />
        </>
      )}
      <Text
        color={theme.palette.Grey90}
        Margin="40px 20px 10px 20px"
        type="h3"
        size="large"
        weight="bold"
      >
        {getText("YourProfile")}
      </Text>
      <ListItem
        darkest
        visible={isPlannerProcessing()}
        icon={<IoWarningOutline color={theme.palette.Yellow100} size={30} />}
        title={getText("Profile.View.AfterToBePlannerTitle")}
        description={getText("Profile.View.AfterToBePlannerDescription")}
      />
      <ListItem title={getText("Username")} description={user?.username}>
        <HighligtedText
          text={getText("General.Planner")}
          visible={user?.type === UserType.Planner}
          backgroundColor={theme.palette.Yellow100}
        />
      </ListItem>
      <ListItem title={getText("Email")} description={user?.email} darkest />
      <ListItem
        title={getText("General.District")}
        description={
          user.district ? user.district.name : getText("Profile.View.Empty")
        }
      />
      <ListItem
        title={getText("General.Branch")}
        description={
          user.branch
            ? `${user.branch.branch_number}, ${user.branch.name}`
            : getText("Profile.View.Empty")
        }
        darkest={true}
      />
      <ListItem
        title={getText("General.Section")}
        description={
          user.section ? user.section.name : getText("Profile.View.Empty")
        }
        darkest={false}
      />
      <ListItem
        darkest
        title={getText("FirstName")}
        description={user?.firstName || getText("Profile.View.Empty")}
      />
      <ListItem
        title={getText("LastName")}
        description={user?.lastName || getText("Profile.View.Empty")}
      />
      <ListItem
        darkest
        title={getText("PhoneNumber")}
        description={user?.phoneNumber || getText("Profile.View.Empty")}
      />
      <ListItem
        title={getText("CreateDate")}
        description={user?.createDate && formatDate(user?.createDate)}
      />
      <Text
        color={theme.palette.Grey90}
        Margin="10px 20px"
        type="h4"
        size="large"
        weight="medium"
      >
        {getText("Profile.View.Options")}
      </Text>
      <HBox Padding="0 10px">
        <Bar type={BarType.Info} title="Oddział">
          <p style={{ margin: 0 }}>
            {getText("Profile.View.InformationContactWithAdmin")} (
            <a href="mailto:pigeonmap@digging.pl">
              {getText("Profile.View.InformationEmail")}
            </a>
            ).
          </p>
        </Bar>
      </HBox>
      <ListItem
        darkest={!isPlannerRequestVisible()}
        rIcon={<IoCreateOutline color={theme.palette.White} size={20} />}
        title={getText("Profile.View.OptionsEditProfileTitle")}
        description={getText("Profile.View.OptionsEditProfileDescription")}
        action={ListItemAction.Primary}
        onClick={() => setProfileModal(ProfileModal.EDIT)}
      />
      <ListItem
        darkest
        visible={isPlannerRequestVisible()}
        rIcon={<IoBuildOutline color={theme.palette.White} />}
        title={getText("Profile.View.OptionsPlannerTitle")}
        description={getText("Profile.View.OptionsPlannerDescription")}
        action={ListItemAction.Success}
        onClick={() => setProfileModal(ProfileModal.CONFIRM_PLANNER)}
      />
      <ListItem
        rIcon={<IoNewspaperOutline color={theme.palette.White} />}
        title={getText("Profile.View.OptionsNewsTitle")}
        description={getText("Profile.View.OptionsNewsDescription")}
        onClick={() => navigate("/news")}
        action={ListItemAction.Success}
      />
      <ListItem
        darkest
        rIcon={<IoEllipsisVerticalOutline color={theme.palette.Grey80} />}
        title={getText("Profile.View.OptionsMoreOptionsTitle")}
        description={getText("Profile.View.OptionsMoreOptionsDescription")}
        action={ListItemAction.Secondary}
        onClick={() => setProfileModal(ProfileModal.MORE)}
      />
    </VBox>
  );
};

export default withNavbar(Profile);

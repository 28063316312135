import { Flight } from "./../models/Flight";
import { FlightType } from "../models/enums/FlightType";

type FlightOrNull = Flight | null;

export default class FlightHelper {
  /**
   * Checks if the given object is a flight.
   * @param flight The object to check.
   * @returns {boolean} Returns true if the object is a flight, false otherwise.
   */
  static isFlight(flight?: any): flight is Flight {
    if (!flight) return false;
    if (typeof flight !== "object") return false;
    if (typeof flight.id !== "number") return false;
    return (
      !!flight.id &&
      !!flight.name &&
      !!flight.startDate &&
      !!flight.start &&
      flight.type !== undefined
    );
  }
  /**
   * Checks if the given flight is a child flight. In other words, if the given flight has a parent flight.
   * @param flight The flight to check, that is a child flight or not.
   * @returns {boolean} - Returns true if the flight is a child flight, false otherwise.
   */
  static isChild(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    if (!flight.parentFlightId) return false;
    return true;
  }
  /**
   * Checks if the given flight is a parent flight. In other words, if the given flight has child flights (relatedFlights).
   * @param flight The flight to check, that is a parent flight or not.
   * @returns {boolean} - Returns true if the flight is a parent flight, false otherwise.
   */
  static isParent(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    if (!flight.relatedFlights) return false;
    if (!flight.relatedFlights.length) return false;
    return true;
  }

  /**
   * Checks if the given flight is a single flight. Check by type property (FlightType.Single). It mean that the flight is private flight for one user only.
   * @param flight The flight to check, that is a single flight or not.
   * @returns {boolean} - Returns true if the flight is a single flight, false otherwise.
   */
  static isSingle(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    return flight.type === FlightType.Single;
  }

  /**
   * Checks if the given flight is a single flight and the current user is the owner of the flight.
   * @param flight The flight to check, that is a single flight and the current user is the owner of the flight.
   * @param userId The current user id.
   * @returns {boolean} - Returns true if the flight is a single flight and the current user is the owner of the flight, false otherwise.
   */
  static isCurrentUserSingle(flight?: FlightOrNull, userId?: number): boolean {
    return (
      FlightHelper.isSingle(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    );
  }

  /**
   * Checks if the given flight is an event. Check by type property (FlightType.Open). It mean that the flight is public flight for all users with the same district and branch.
   * @param flight The flight to check, that is an event or not.
   * @returns {boolean} - Returns true if the flight is an event, false otherwise.
   */
  static isEvent(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    return flight.type === FlightType.Open;
  }

  /**
   * Checks if the given flight based on the event flight. It mean that the given flight is a child of the event flight.
   * @param flight The flight to check, that is a child of the event flight or not.
   * @returns {boolean} - Returns true if the flight is a child of the event flight, false otherwise.
   */
  static isEventChild(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    if (!flight.parentFlight) return false;
    return FlightHelper.isEvent(flight.parentFlight);
  }

  /**
   * Checks if the given flight is an event child and the current user is the owner of the flight.
   * @param flight The flight to check, that is an event and the current user is the owner of the flight.
   * @param userId The current user id.
   * @returns {boolean} - Returns true if the flight is an event and the current user is the owner of the flight, false otherwise.
   */
  static isCurrentUserEventChild(
    flight?: FlightOrNull,
    userId?: number
  ): boolean {
    return (
      FlightHelper.isEventChild(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    );
  }

  /**
   * Checks if the given flight is an event and the current user is the owner of the flight.
   * @param flight The flight to check, that is an event and the current user is the owner of the flight.
   * @param userId The current user id.
   * @returns {boolean} - Returns true if the flight is an event and the current user is the owner of the flight, false otherwise.
   */
  static isCurrentUserEvent(flight?: FlightOrNull, userId?: number): boolean {
    return (
      FlightHelper.isEvent(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    );
  }
  /**
   * Checks if the given flight is an event and the current user has edit permissions.
   * @param flight
   * @param userId
   * @returns {boolean}
   */
  static isEventEditPermission(
    flight?: FlightOrNull,
    userId?: number
  ): boolean {
    if (!flight || !userId) return false;
    return (
      FlightHelper.isEvent(flight) &&
      (FlightHelper.isCurrentUserFlight(flight, userId) ||
        !!flight?.sharing?.find((s) => s.userId === userId))
    );
  }

  /**
   * Checks if the given flight is an event and the current user has edit permissions. The permission is also when flight is undefined!!!
   * @param flight
   * @param userId
   * @returns {boolean}
   */
  static isEditable(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight || !userId) return true;
    return (
      FlightHelper.isCurrentUserFlight(flight, userId) ||
      FlightHelper.isEventEditPermission(flight, userId)
    );
  }

  /**
   * Checks if the given flight is a private event. It mean that the given flight is a single flight and has child flights.
   * @param flight The flight to check, that is a private event or not.
   * @returns {boolean} - Returns true if the flight is a private event, false otherwise.
   */
  static isPrivateEvent(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    if (flight.type === FlightType.Single && flight.relatedFlights.length > 0)
      return true;
    if (!!flight.parentFlight && flight.parentFlight.type === FlightType.Single)
      return true;
    return false;
  }

  /**
   * Checks if the given flight is a private event and the current user is the owner of the flight.
   * @param flight The flight to check, that is a private event and the current user is the owner of the flight.
   * @param userId The current user id.
   * @returns {boolean} - Returns true if the flight is a private event and the current user is the owner of the flight, false otherwise.
   */
  static isCurrentUserPrivateEvent(
    flight?: FlightOrNull,
    userId?: number
  ): boolean {
    return (
      FlightHelper.isPrivateEvent(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    );
  }

  /**
   * Checks if the given flight parent owner is the current user.
   * @param flight The flight to check if the parent owner is the current user.
   * @param userId The current user id.
   * @returns {boolean} - Returns true if the parent owner is the current user, false otherwise.
   */
  static isParentCurrentUser(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight || !userId) return false;
    if (!flight.parentFlight) return false;
    return flight.parentFlight.user.id === userId;
  }

  /**
   * Checks if the given flight parent is a Single flight, so it is mean that flight is attend to a private event.
   * @param flight The flight to check if the parent is a Single flight.
   * @returns {boolean} - Returns true if the parent is a Single flight, false otherwise.
   */
  static isPrivateEventChild(flight?: FlightOrNull): boolean {
    if (!flight) return false;
    if (!flight.parentFlight) return false;
    return FlightHelper.isSingle(flight.parentFlight);
  }

  /**
   * Checks if the given flight is a private event child and the current user is the owner of the flight.
   * @param flight
   * @param userId
   * @returns {boolean}
   */
  static isCurrentUserPrivateEventChild(
    flight?: FlightOrNull,
    userId?: number
  ): boolean {
    return (
      FlightHelper.isPrivateEventChild(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    );
  }
  /**
   * Checks if the given flight own to the current user.
   * @param flight
   * @param userId
   * @returns {boolean}
   */
  static isCurrentUserFlight(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight || !userId) return false;
    return flight.user.id === userId;
  }

  /**
   * Checks if the given flight is a shared flight. It mean that the flight is shared to others users
   * @param flight
   * @param userId
   * @returns {boolean}
   */
  static isShared(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight) return false;
    return (
      !FlightHelper.isCurrentUserFlight(flight, userId) &&
      FlightHelper.isSingle(flight) &&
      flight.sharing.length > 0
    );
  }

  static isDovecoteInitalValue(
    flight?: FlightOrNull,
    userId?: number
  ): boolean {
    if (!flight) return true;
    if (
      FlightHelper.isSingle(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    )
      return true;
    if (
      FlightHelper.isEventEditPermission(flight, userId) &&
      !FlightHelper.isCurrentUserFlight(flight, userId)
    )
      return false;
    if (
      FlightHelper.isEvent(flight) &&
      !FlightHelper.isCurrentUserFlight(flight, userId)
    )
      return false;
    return true;
  }

  static isDisabledDovecote(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight || !userId) return false;
    if (
      FlightHelper.isEventEditPermission(flight, userId) &&
      !FlightHelper.isCurrentUserFlight(flight, userId)
    )
      return true;
    if (FlightHelper.isEvent(flight))
      return FlightHelper.hasRelatedFlight(flight, userId);
    if (FlightHelper.isSharing(flight, userId)) return true;
    return false;
  }

  static showJoin(flight?: FlightOrNull, userId?: number) {
    if (!flight || !userId) return false;
    if (FlightHelper.isCurrentUserFlight(flight, userId) && !!flight.endDate)
      return false;
    if (FlightHelper.isEvent(flight) || FlightHelper.isPrivateEvent(flight)) {
      if (FlightHelper.hasRelatedFlight(flight, userId)) return false;
      return true;
    }
    return false;
  }

  static hasRelatedFlight(flight?: FlightOrNull, userId?: number) {
    if (!flight || !userId) return false;
    if (flight.relatedFlights) {
      const relatedFlight = flight.relatedFlights.find(
        (f) => f.userId === userId
      );
      if (relatedFlight) return true;
    }
    return false;
  }

  static isStartDateDisabled(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight || !userId) return false;
    if (FlightHelper.isEventChild(flight)) return true;
    if (
      FlightHelper.isSingle(flight) &&
      FlightHelper.isCurrentUserFlight(flight, userId)
    )
      return false;
    if (FlightHelper.isEventEditPermission(flight, userId)) return false;
    return true;
  }

  /**
   * Checks if flight is shared to the current user.
   * @param flight
   * @param userId
   * @returns {boolean}
   */
  static isSharing(flight?: FlightOrNull, userId?: number): boolean {
    if (!flight || !userId) return false;
    const sharing = flight.sharing.find((s) => s.userId === userId);
    return (
      !!sharing && flight?.user.id !== userId
    );
  }

  /**
   * Check if the flight can have weather layers (THIS IS WORKAROUND)
   * @param flight
   * @returns {boolean}
   */
  static isWeatherLayer(flight?: FlightOrNull): boolean {
    if (!flight) return true;
    const startDate = new Date(flight.startDate);
    const tenHBefore = new Date();
    tenHBefore.setHours(tenHBefore.getHours() - 10);

    return startDate >= tenHBefore;
  }
}

import { ComponentType, useEffect, useState } from "react";
import ErrorMessages, { IErrorMessage } from "./ErrorMessages";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { AxiosError } from "axios";
import { ButtonType } from "../../styles/Button.styled";
import HalfModal from "../../components/HalfModal/HalfModal";
import Text from "../../components/Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import { VBox } from "../../styles/Flexbox.styled";
import { getText } from "../../locales/initI18n";
import { resetAuth } from "../../redux/modules/auth";
import { setError } from "../../redux/modules/error";
import { useTheme } from "styled-components";

function withError<T extends {}>(Component: ComponentType<T>) {
  return (props: T) => {
    const theme = useTheme();
    const [isVisible, setIsVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
    const [height, setHeight] = useState(1.5);
    const error = useAppSelector((state) => state.error.error);
    const dispatch = useAppDispatch();
    const getErrorMsg = (error: unknown) => {
      if (error instanceof AxiosError) {
        return error.response?.data.message as string[];
      }
      if (error instanceof Error) return [error["message"]] as string[];
      return undefined;
    };
    useEffect(() => {
      if (error) {
        try {
          const message = getErrorMsg(error);
          //
          if (!message) throw new Error();

          const isUnAuthorized =
            message?.includes("UNATHORIZED") ||
            message?.includes("INVALID_TOKEN");

          const isEmailNotConfirmed = message?.includes("EMAIL_NOT_VERIFIED");

          if (isUnAuthorized) {
            dispatch(resetAuth());
            return;
          }

          if (isEmailNotConfirmed) {
            dispatch(resetAuth());
          }

          const messages = message.reduce<IErrorMessage[]>((prev, curr) => {
            const errorMessage = ErrorMessages[curr];
            if (!errorMessage) return prev;
            return [...prev, errorMessage];
          }, []);

          if (!messages.length) throw new Error(message.join(", "));
          if (messages.length === 1) setHeight(messages[0]?.height || 1.5);
          else setHeight(1.5);

          setErrorMessage(messages);
          setIsVisible(true);
        } catch (e) {
          const message = getErrorMsg(error);
          if (!message)
            return console.warn("withError: Error message not found.");
          const errorMessage: IErrorMessage[] = [
            {
              title: getText("Error.UnknowError"),
              message: `${getText("Error.UnknowErrorMessage")} ${message.join(
                ", "
              )}
              `,
            },
          ] as IErrorMessage[];
          setErrorMessage(errorMessage);
          setIsVisible(true);
        }
      }
    }, [error]);

    const onClose = () => {
      setIsVisible(false);
      dispatch(setError(null));
    };

    return (
      <>
        <Component {...(props as T)} />
        <HalfModal
          visible={isVisible}
          onClose={onClose}
          closeType={ButtonType.Critical}
          heightDivider={height}
          isExpander={false}
          zIndex={1}
        >
          <Text
            visible={errorMessage.length > 1}
            type={TextTypeEnum.h4}
            size="large"
            weight="bold"
            Margin="0 0 10px 0"
          >
            {getText("FewProblems")}
          </Text>
          <VBox
            visible={errorMessage.length > 1}
            height="4px"
            width="100%"
            backgroundColor={theme.palette.Red100}
            Margin="0 0 40px 0"
          />
          {errorMessage.map((message, index) => (
            <VBox key={`${index}${message.title}`}>
              <Text
                type={TextTypeEnum.h5}
                size="large"
                weight="bold"
                Margin="0 0 10px 0"
              >
                {getText(message?.title || "")}
              </Text>
              <VBox
                height="3px"
                width="60%"
                backgroundColor={theme.palette.Red70}
              />
              <Text
                type="body"
                size={TextSizeEnum.medium}
                weight="regular"
                Margin="10px 0 20px 0"
              >
                {getText(message?.message || "")}
              </Text>
            </VBox>
          ))}
        </HalfModal>
      </>
    );
  };
}

export default withError;

import { authDelete, authGet, authPost } from "./BaseService";

import Egg from "../models/Egg";
import { EggState } from "../models/enums/EggStateEnum";
import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import PigeonPair from "../models/PigeonPair";

export const getEggs = async (options: PageOptions) => {
  const result = await authGet<object[]>(`egg?${options.getParams()}`);

  const page = new Page<Egg>().fillData(result.data);
  page.data = page.data.map((item) => Egg.create(item));
  return page;
};

export const getEgg = async (id?: number | string) => {
  if (!id) return new Egg();
  const result = await authGet(`egg/${id}`);
  return Egg.create(result.data);
};

export const addEgg = async (
  pair: PigeonPair,
  dateOfLaying: Date,
  status: EggState,
  brood: number
) => {
  const result = await authPost("egg", {
    pairId: pair.id,
    dateOfLaying,
    status,
    brood,
  });
  return Egg.create(result.data);
};

export const deleteEgg = async (id: number) => {
  await authDelete(`egg/${id}`);
};

export const hatchEgg = async (egg: Egg, hatchDate: Date) => {
  return authPost(`egg/hatch`, { id: egg.id, hatchDate });
};

export enum DatePatternEum {
  year = "yyyy",
  month = "MM",
  day = "dd",
  hour12 = "hh",
  hour24 = "HH",
  minute = "mm",
  second = "ss",
  dayPeriod = "a",
}

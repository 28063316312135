import "@watergis/maplibre-gl-legend/dist/maplibre-gl-legend.css";

import * as maptilersdk from "@maptiler/sdk";

import {
  MapBuilderMarker,
  MapBuilderMarkerOptions,
  Marker,
} from "../models/Marker";
import {
  PrecipitationLayer,
  TemperatureLayer,
  TileLayer,
  WindLayer,
} from "@maptiler/weather";

import { MapLayerEnum } from "../models/enums/MapLayerEnum";

class MapBuilder {
  private _CURRENT_MAP_MARKER_NAME = "currentMapMarker";
  private _ACTIVE_MARKER_CLASS = "active-marker";
  private KEY = "uhGlpYQIgcjDS9lNXlxy";
  private id: string;
  private map: maptilersdk.Map;
  private windLayer!: WindLayer;
  private tempLayer!: TemperatureLayer;
  private precipitationLayer!: PrecipitationLayer;
  private currentLayer!: TileLayer;
  private _markers: MapBuilderMarker[] = [];
  private _clickMapEnabled = true;
  private _activeMarker: MapBuilderMarker | null = null;

  constructor(id: string, onClickMap: (marker: Marker | null) => void) {
    this.id = id;

    maptilersdk.config.apiKey = this.KEY;

    this.map = new maptilersdk.Map({
      container: this.id,
      style: maptilersdk.MapStyle.DATAVIZ.DARK,
      navigationControl: false,
      geolocateControl: false,
    });

    this._setLayers();

    const map = this.map;
    map.on("load", (event) => {
      map.setPaintProperty("Water", "fill-color", "rgba(0, 0, 0, 0.5)");
      map.addLayer(this.precipitationLayer, "Water");
      map.addLayer(this.tempLayer, "Water");
      map.addLayer(this.windLayer, "Water");

      this.hideLayer(this.precipitationLayer.id);
      this.hideLayer(this.tempLayer.id);

      event.target.resize();
    });
    map.on("click", (e) => this.onClickMap(e, onClickMap));
  }

  public getMap = () => this.map;
  public getClickMapEnabled = () => this._clickMapEnabled;
  public enableClickMap = () => (this._clickMapEnabled = true);
  public disableClickMap = () => (this._clickMapEnabled = false);

  private _setLayers() {
    this.windLayer = new WindLayer();
    this.tempLayer = new TemperatureLayer();
    this.precipitationLayer = new PrecipitationLayer();
    this.currentLayer = this.windLayer;
  }

  public changeLayer(layer: MapLayerEnum) {
    this.hideLayer(this.currentLayer.id);
    const time = this.currentLayer.getAnimationTime();
    switch (layer) {
      case MapLayerEnum.Wind:
        this.currentLayer = this.windLayer;
        break;
      case MapLayerEnum.Temperature:
        this.currentLayer = this.tempLayer;
        break;
      case MapLayerEnum.Precipitation:
        this.currentLayer = this.precipitationLayer;
        break;
    }
    this.setLayerTime(time);
    this.showLayer(this.currentLayer.id);
  }

  public hideCurrentLayer() {
    this.hideLayer(this.currentLayer.id);
  }

  public showCurrentLayer() {
    this.showLayer(this.currentLayer.id);
  }

  public hideLayer(id: string) {
    this.map.setLayoutProperty(id, "visibility", "none");
  }

  public showLayer(id: string) {
    this.map.setLayoutProperty(id, "visibility", "visible");
  }

  setLayerTime(value: Date): void;
  setLayerTime(value: string): void;
  setLayerTime(value: number): void;
  public setLayerTime(value: number | Date | string) {
    value = typeof value === "string" ? new Date(value) : value;
    if (value instanceof Date) {
      this.currentLayer.setAnimationTime(value.getTime() / 1000);
    } else {
      this.currentLayer.setAnimationTime(value);
    }
  }

  public setCurrentLayerTime() {
    const date = new Date();
    this.currentLayer.setAnimationTime(date.getTime() / 1000);
  }

  public onClickMap(
    e: maptilersdk.MapMouseEvent,
    callback: (marker: Marker | null) => void
  ) {
    if (!this.getClickMapEnabled()) return;
    const currentMarker = this.getMarkerByName(this._CURRENT_MAP_MARKER_NAME);
    if (currentMarker) {
      this.removeMarkerByName(this._CURRENT_MAP_MARKER_NAME);
      return callback(null);
    }
    const marker = {
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    } as Marker;
    this.addMarker(marker, {
      name: this._CURRENT_MAP_MARKER_NAME,
    });
    callback(marker);
  }

  protected _addMarkerElement(image: string) {
    const element = document.createElement("div");
    element.style.backgroundImage = image;
    element.style.width = "24px";
    element.style.height = "24px";
    element.style.backgroundSize = "cover";
    element.style.backgroundRepeat = "no-repeat";
    element.style.backgroundPosition = "center";
    element.style.cursor = "pointer";

    return element;
  }

  public addMarker<T>(
    markerData: Marker,
    options?: MapBuilderMarkerOptions<T>
  ) {
    if (options?.image) options.element = this._addMarkerElement(options.image);
    if (
      markerData.longitude > 90 ||
      markerData.longitude < -90 ||
      markerData.latitude > 90 ||
      markerData.latitude < -90
    ) {
      console.error("Invalid coordinates. Not in range (90, -90)");
      return;
    }
    const marker = new maptilersdk.Marker(options)
      .setLngLat([markerData.longitude, markerData.latitude])
      .addTo(this.map);

    marker.getElement().addEventListener("click", (event) => {
      event.stopPropagation();
      this.setActiveMarker([markerData.latitude, markerData.longitude]);
      options?.onClick?.(markerData as T);
    });

    const mapBuilderMarker = {
      mapMarker: marker,
      ...markerData,
      ...options,
    } as MapBuilderMarker;

    this._markers.push(mapBuilderMarker);

    return mapBuilderMarker;
  }

  public changeMarkerIcon(name: string, icon: string) {
    const marker = this.getMarkerByName(name);
    if (!marker) return;
    marker.mapMarker.getElement().style.backgroundImage = `url(${icon})`;
  }

  public getCurrentMarker() {
    return this.getMarkerByName(this._CURRENT_MAP_MARKER_NAME);
  }

  public getMarkerByName(name: string) {
    return this._markers.find((marker) => marker.name === name);
  }

  public getMarkerByCoordinate(coordinate: [number, number]) {
    return this._markers.find(
      (marker) =>
        marker.latitude.toString() === coordinate[0].toString() &&
        marker.longitude.toString() === coordinate[1].toString()
    );
  }

  protected getMarkers() {
    return this._markers;
  }

  public addMarkers(markers: Marker[], options?: MapBuilderMarkerOptions) {
    markers.forEach((marker) => {
      this.addMarker(marker, options);
    });
  }

  public removeMarkersByGroup(group: string | number) {
    this._markers = this._markers.filter((marker) => {
      if (marker.group == group) {
        marker.mapMarker.remove();
        return false;
      }
      return true;
    });
  }

  public removeMarkerByName(name: string) {
    this._markers = this._markers.filter((marker) => {
      if (marker.name === name) {
        marker.mapMarker.remove();
        return false;
      }
      return true;
    });
  }

  public removeMarkerByCoordinate(coordinate: [number, number]) {
    this._markers = this._markers.filter((marker) => {
      if (
        marker.latitude.toString() === coordinate[0].toString() &&
        marker.longitude.toString() === coordinate[1].toString()
      ) {
        marker.mapMarker.remove();
        return false;
      }
      return true;
    });
  }
  public setActiveMarker(coordinate: [number, number]) {
    this.unsetActiveMarker();
    const marker = this.getMarkerByCoordinate(coordinate);
    if (!marker) return;
    marker.mapMarker.getElement().classList.add(this._ACTIVE_MARKER_CLASS);
    this._activeMarker = marker;
  }
  public unsetActiveMarker() {
    if (!this._activeMarker) return;
    this._activeMarker.mapMarker
      .getElement()
      .classList.remove(this._ACTIVE_MARKER_CLASS);
    this._activeMarker = null;
  }
  /**
   * If the line already exists, it will be updated
   * @param name of the line. This should be unique
   * @param markers array of markers. Coordinates to draw the line
   * @param color string
   */
  public addLine(name: string, markers: Marker[], color = "#FFF") {
    const coordinates = markers.map((marker) => [
      marker.longitude,
      marker.latitude,
    ]);
    if (this.map.getSource(name) !== undefined) {
      const source = this.map.getSource(name) as maptilersdk.GeoJSONSource;
      source.setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates,
        },
      });
      return;
    }
    this.map.addSource(name, {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates,
        },
      },
    });
    this.map.addLayer({
      id: name,
      type: "line",
      source: name,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 4,
      },
    });
  }

  public removeLine(name: string) {
    if (this.map.getLayer(name) === undefined) {
      console.warn(`Layer ${name} not found`);
      return;
    }
    this.map.removeLayer(name);
    this.map.removeSource(name);
  }

  public zoomToMarkers(markers: Marker[], padding = 10) {
    const bounds = new maptilersdk.LngLatBounds();
    markers.forEach((marker) => {
      bounds.extend([marker.longitude, marker.latitude]);
    });
    this.map.fitBounds(bounds, { padding });
  }
}

export default MapBuilder;

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import PageOptions from "../models/PageOptions";
import { getPigeons } from "../services/PigeonService";

const usePigeons = (options = new PageOptions()) => {
  const queryClient = useQueryClient();

  const queryFlights = useInfiniteQuery({
    queryKey: options?.getQueryKey("pigeons"),
    queryFn: ({ pageParam = 1 }) => getPigeons(options.setPage(pageParam)),
    getNextPageParam: options?.nextPagefn,
  });

  const removeCaches = () => {
    queryClient.invalidateQueries(["pigeons"]);
    queryClient.invalidateQueries(["pigeon"]);
  };

  return {
    pigeons: queryFlights.data?.pages.flatMap((page) => page.data) || [],
    lastMeta: queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta,
    refetch: queryFlights.refetch,
    fetchNextPage: queryFlights.fetchNextPage,
    removeCaches,
    invalidate: removeCaches,
  };
};

export default usePigeons;

import { Base } from "./Base";
import { Marker } from "./Marker";
import { TagType } from "./enums/TagType";

export class Tag extends Base implements ITag {
  name!: string;
  remarks!: string;
  city!: string;
  country!: string;
  type!: TagType;
  isVisible!: boolean;
  isDefault!: boolean;
  isCote!: boolean;
  latitude: number = 0;
  longitude: number = 0;
  color?: string;
  icon?: string;
}

export interface ITag extends Marker {
  id: number;
  createDate: Date;
  updateDate: Date;
  name: string;
  remarks: string;
  city: string;
  country: string;
  type: TagType;
  isVisible: boolean;
  isDefault: boolean;
  isCote: boolean;
}

export interface CreateFlightTag {
  latitude?: number;
  longitude?: number;
  tag?: number;
}

import { ChildFilterType } from "./ChildFilterType";
import DateHelper from "../../utils/DateHelper";
import DefinedFilter from "./DefinedFilter";
import { FilterName } from "../enums/FilterName";
import { FilterRule } from "../enums/FilterRule";
import { Flight } from "../Flight";
import { TagType } from "../enums/TagType";
import { getText } from "../../locales/initI18n";

export default class DefinedFilters {
  private _definedFilters: DefinedFilter[] = [];

  public get definedFilters(): DefinedFilter[] {
    return this._definedFilters;
  }

  public getFilter(name: FilterName): DefinedFilter {
    const filter = this._definedFilters.find((filter) => filter.name === name);
    if (!filter) {
      throw new Error(`DefinedFilters: Filter "${name}" not found.`);
    }
    return filter;
  }

  constructor() {
    this._definedFilters = [
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Id"),
        name: FilterName.Id,
        placeholder: getText("FilterObjects.Placeholder.Id"),
        type: ChildFilterType.Number,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.NotId"),
        name: FilterName.NotId,
        queryName: FilterName.Id,
        placeholder: getText("FilterObjects.Placeholder.NotId"),
        type: ChildFilterType.Number,
        rule: FilterRule.NOT_EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Name"),
        name: FilterName.Name,
        placeholder: getText("FilterObjects.Placeholder.Name"),
        type: ChildFilterType.Text,
        rule: FilterRule.LIKE,
        transformCallback: (value) => {
          if (value) return value;
        },
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.OpenFlights"),
        name: FilterName.OpenFlights,
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.PrivateFlights"),
        name: FilterName.PrivateFlights,
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Type"),
        name: FilterName.Type,
        placeholder: "",
        type: ChildFilterType.Number,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.ParentFlightId"),
        name: FilterName.ParentFlightId,
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.IS_NOT_NULL,
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.FutureFlight,
        queryName: "startDate",
        placeholder: "",
        type: ChildFilterType.Text,
        rule: FilterRule.GREATER_THAN,
        transformCallback: () => {
          return `'${DateHelper.getISOString(Flight.LimitPresentDateEnd())}'`;
        },
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.PresentFlightStart,
        queryName: "startDate",
        placeholder: "",
        type: ChildFilterType.Text,
        rule: FilterRule.GREATER_THAN_OR_EQUALS,
        transformCallback: () => {
          return `'${DateHelper.getISOString(Flight.LimitPresentDateStart())}'`;
        },
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.PresentFlightEnd,
        queryName: "startDate",
        placeholder: "",
        type: ChildFilterType.Text,
        rule: FilterRule.LESS_THAN_OR_EQUALS,
        transformCallback: () => {
          return `'${DateHelper.getISOString(Flight.LimitPresentDateEnd())}'`;
        },
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.HistoryFlight,
        queryName: "startDate",
        placeholder: "",
        type: ChildFilterType.Text,
        rule: FilterRule.LESS_THAN,
        transformCallback: () => {
          return `'${DateHelper.getISOString(Flight.LimitPresentDateStart())}'`;
        },
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.NotFinishFlight,
        queryName: "userEndDate",
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.IS_NULL,
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.FinishFlight,
        queryName: "userEndDate",
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.IS_NOT_NULL,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.StartDate"),
        name: FilterName.StartDate,
        placeholder: "",
        type: ChildFilterType.Boolean,
        transformCallback: (value) => {
          if (value === true) {
            return `'${new Date().toISOString()}'`;
          }
        },
        rule: FilterRule.GREATER_THAN_OR_EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.EndDate"),
        name: FilterName.EndDate,
        placeholder: "",
        type: ChildFilterType.Boolean,
        transformCallback: (value) => {
          if (value === true) {
            return `'${new Date().toISOString()}'`;
          }
        },
        rule: FilterRule.LESS_THAN_OR_EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Country"),
        name: FilterName.Country,
        placeholder: getText("FilterObjects.Placeholder.Country"),
        type: ChildFilterType.Text,
        rule: FilterRule.LIKE,
        transformCallback: (value) => {
          if (value) return value;
        },
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.City"),
        name: FilterName.City,
        placeholder: getText("FilterObjects.Placeholder.City"),
        type: ChildFilterType.Text,
        rule: FilterRule.LIKE,
        transformCallback: (value) => {
          if (value) return value;
        },
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.CreatedByYou"),
        name: FilterName.CreatedByYou,
        queryName: FilterName.Type,
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
        transformCallback: (value) => {
          if (value) return TagType.Private;
        },
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Predefined"),
        name: FilterName.Predefined,
        queryName: FilterName.Type,
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
        transformCallback: (value) => {
          if (value) return TagType.Predefined;
        },
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.SharedFlights"),
        name: FilterName.SharedFlights,
        queryName: "sharing.userId",
        placeholder: "",
        type: ChildFilterType.Boolean,
        transformCallback: (value) => {
          if (value) return value;
        },
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.DistrictId,
        queryName: "user.districtId",
        placeholder: "",
        type: ChildFilterType.Number,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.UserId,
        queryName: "userId",
        placeholder: "",
        type: ChildFilterType.Number,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Ring"),
        name: FilterName.Ring,
        placeholder: getText("FilterObjects.Placeholder.Ring"),
        type: ChildFilterType.Text,
        rule: FilterRule.LIKE,
        transformCallback: (value) => {
          if (value) return value;
        },
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.Sex"),
        name: FilterName.Sex,
        placeholder: getText("FilterObjects.Placeholder.Sex"),
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.CreatedUserId"),
        name: FilterName.CreatedUserId,
        placeholder: getText("FilterObjects.Placeholder.CreatedUserId"),
        type: ChildFilterType.Number,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.PigeonId"),
        name: FilterName.PigeonId,
        placeholder: getText("FilterObjects.Placeholder.PigeonId"),
        type: ChildFilterType.Number,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.UseInPedigree"),
        name: FilterName.UseInPedigree,
        placeholder: getText("FilterObjects.Placeholder.UseInPedigree"),
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.MotherId"),
        name: FilterName.MotherIdNull,
        queryName: FilterName.MotherId,
        placeholder: getText("FilterObjects.Placeholder.MotherId"),
        type: ChildFilterType.Text,
        rule: FilterRule.IS_NULL,
      }),
      new DefinedFilter({
        displayName: getText("FilterObjects.DisplayName.FatherId"),
        name: FilterName.FatherIdNull,
        queryName: FilterName.FatherId,
        placeholder: getText("FilterObjects.Placeholder.FatherId"),
        type: ChildFilterType.Text,
        rule: FilterRule.IS_NULL,
      }),
      new DefinedFilter({
        displayName: "",
        placeholder: "",
        name: FilterName.UserIdNull,
        queryName: FilterName.UserId,
        type: ChildFilterType.Text,
        rule: FilterRule.IS_NULL,
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.IsCote,
        placeholder: "",
        type: ChildFilterType.Boolean,
        rule: FilterRule.EQUALS,
      }),
      new DefinedFilter({
        displayName: "",
        name: FilterName.Username,
        placeholder: "",
        type: ChildFilterType.Text,
        rule: FilterRule.LIKE,
      }),
    ];

    // check if all filters have unique names and show warning if not unique
    const uniqueNames = new Set<FilterName>();
    this._definedFilters.forEach((filter) => {
      if (uniqueNames.has(filter.name)) {
        throw new Error(
          `DefinedFilters: Filter name "${filter.name}" is not unique.`
        );
      }
      uniqueNames.add(filter.name);
    });
  }
}

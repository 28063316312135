import { Base } from "./Base";
import Pigeon from "./Pigeon";
import Season from "./Season";
import User from "./User";

export default class PigeonPair extends Base {
  male!: Pigeon;
  female!: Pigeon;
  user = new User();
  season = new Season();
  eggs: unknown[] = [];

  fillData(data: object): this {
    super.fillData(data);
    this.male = Pigeon.create(this.male);
    this.female = Pigeon.create(this.female);
    this.user = User.create(this.user);
    this.season = Season.create(this.season);
    return this;
  }

  get maleRingNumber(): string {
    return this.male.ring.number;
  }
  get femaleRingNumber(): string {
    return this.female.ring.number;
  }
}

import Modal from "react-modal";
import { VBox } from "./Flexbox.styled";
import { devices } from "./Devices";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  bottom: 0;
  border: none;
  padding: 0;
  width: 100%;
  background-color: transparent;
  position: absolute;
  border-radius: 20px;
  @media ${devices.md} {
    position: absolute;
    max-width: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ModalContent = styled(VBox)`
  background-color: ${({ theme }) => theme.palette.White};
  border-radius: 20px 20px 0 0;
  position: relative;

  @media ${devices.md} {
    border-radius: 20px;
    overflow: hidden;
  }
`;

import {
  Breadcrumbs,
  BreadcrumbsItem,
  DynamicPage,
  DynamicPageHeader,
  DynamicPageTitle,
  FilterBar,
  FilterGroupItem,
  FlexBox,
  Label,
  MessageBoxAction,
  MessageBoxType,
  Tab,
  TabContainer,
  TabSeparator,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableSelectionDomRef,
  Title,
  Toolbar,
  ToolbarButton,
  ToolbarSeparator,
  ToolbarSpacer,
  VariantItem,
  VariantManagement,
} from "@ui5/webcomponents-react";
import { HBox, VBox } from "../styles/Flexbox.styled";
import { memo, useEffect, useRef, useState } from "react";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

import { ActivityEnum } from "../models/enums/ActivityEnum";
import AddPigeonForm from "./modals/AddPigeonForm/AddPigeonForm";
import BaseDialogProps from "../models/BaseDialogProps";
import ConfirmMessageHelper from "../utils/ConfirmMessageHelper";
import Debounce from "../utils/Debounce";
import DialogResult from "../models/DialogResult";
import FCLLayout from "@ui5/webcomponents-fiori/dist/types/FCLLayout";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import { PigeonAssignMode } from "../models/enums/PigeonAssignMode";
import PigeonNotSelectedToSeasonTable from "./PigeonNotSelectedToSeasonTable";
import PigeonPair from "../models/PigeonPair";
import PigeonSeasonChickTable from "./PigeonSeasonChickTable";
import PigeonSeasonEggTable from "./PigeonSeasonEggTable";
import PigeonSeasonGroupTable from "./PigeonSeasonGroupTable";
import PigeonSeasonPairTable from "./PigeonSeasonPairTable";
import { PigeonTabEnum } from "../models/enums/PigeonTabEnum";
import PigeonTable from "./PigeonTable";
import RingDialog from "./RingDialog";
import { SeasonAssignmentGroup } from "../models/enums/SeasonAssignmentGroup";
import Select from "./Select/Select";
import SelectOption from "../models/SelectOption";
import TextInput from "./TextInput";
import WithConfirmDialogProps from "../hoc/withConfirmDialog/WithConfirmDialogProps";
import { WithPigeonPairHandlerProps } from "../hoc/WithPigeonPairHandler/WithPigeonPairHandlerProps";
import { addPigeon } from "../services/PigeonService";
import { addToSelection } from "../services/PigeonSeasonSelectionService";
import { assignToSeason } from "../services/PigeonSeasonAssignmentService";
import { breakpoints } from "../styles/Devices";
import { getSeasons } from "../services/SeasonsService";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";
import usePigeonAssignments from "../hooks/usePigeonAssignments";
import usePigeons from "../hooks/usePigeons";
import { useQuery } from "@tanstack/react-query";
import withConfirmDialog from "../hoc/withConfirmDialog/withConfirmDialog";
import withPigeonPairHandler from "../hoc/WithPigeonPairHandler/WithPigeonPairHandler";

export interface PigeonSeasonTableProps {
  slot?: string;
  contentLayout: FCLLayout;
  assignMode: PigeonAssignMode;
  choosenSeason?: number | null;

  onChangeLayout: (layout: FCLLayout) => void;
  onChangeAssignMode: (mode: PigeonAssignMode) => void;
  onChangeSeason: (season: number) => void;
  onPairPress: (pair: PigeonPair) => void;
}

const PigeonSeasonPage = ({
  contentLayout,
  assignMode,
  slot,
  choosenSeason,
  onChangeLayout,
  onChangeAssignMode,
  onChangeSeason,
  onPairPress,
  openDialog,
  openMessage,
}: PigeonSeasonTableProps & WithDialogsProps & WithConfirmDialogProps) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [activity, setActivity] = useState<ActivityEnum>(ActivityEnum.All);
  const [tab, setTab] = useState(PigeonTabEnum.Pair);
  const { removeCaches } = usePigeons(new PageOptions());
  const selection = useRef<TableSelectionDomRef>(null);
  const [isSelection, setIsSelection] = useState(false);

  const onAddPigeon = async () => {
    const result = await openDialog("AddPigeonForm", {
      season: choosenSeason || undefined,
    });

    if (result.isClose()) return;

    const pigeon = result.getData<Pigeon>();

    const added = await addPigeon(pigeon);

    const group = await ConfirmMessageHelper.getSeasonAssignment(openMessage);
    if (!group) return;
    assignToSeason(added, new Date().getFullYear(), group);

    removeCaches();
  };

  const addToSeasonSelection = async () => {
    const selectedPigeonsText = selection.current?.selected || "";
    const selectedPigeonIds = selectedPigeonsText.split(" ");
    const currentYear = new Date().getFullYear();
    for (const id of selectedPigeonIds || []) {
      await addToSelection(Number(id), currentYear);
    }
    removeCaches();
    const confirm = await openMessage(
      getText("PigeonSeasonPage.AddedSelectedPigeonsToList")
    );
    setIsSelection(false);

    if (confirm === MessageBoxAction.OK) {
      navigate(`./${new Date().getFullYear()}/selections`);
    }
  };

  const onOpenRings = async () => {
    await openDialog(RingDialog.name, {});
  };

  const { data: seasons } = useQuery({
    queryKey: ["seasons"],
    queryFn: getSeasons,
  });

  useEffect(() => {
    switch (assignMode) {
      case PigeonAssignMode.Pair:
      case PigeonAssignMode.None:
        setTab(PigeonTabEnum.Pair);
        break;
      case PigeonAssignMode.Group:
        setTab(PigeonTabEnum.Old);
        break;
    }
  }, [assignMode]);

  const createTable = () => {
    if (isSelection)
      return (
        <PigeonNotSelectedToSeasonTable
          year={Number(choosenSeason)}
          selection={selection}
        />
      );
    if (!choosenSeason)
      return (
        <PigeonTable
          searchValue={searchValue}
          activity={activity}
          choosenSeason={choosenSeason}
          interactiveRow
        />
      );
    switch (tab) {
      case PigeonTabEnum.Breeding:
      case PigeonTabEnum.Old:
      case PigeonTabEnum.Young:
        return (
          <PigeonSeasonGroupTable
            interactiveRow
            type={tab}
            choosenSeason={choosenSeason}
          />
        );
      case PigeonTabEnum.Pair:
        return (
          <PigeonSeasonPairTable
            interactiveRow
            onPairPress={onPairPress}
            choosenSeason={choosenSeason}
          />
        );
      case PigeonTabEnum.Egg:
        return <PigeonSeasonEggTable choosenSeason={choosenSeason} />;
      case PigeonTabEnum.Chick:
        return <PigeonSeasonChickTable choosenSeason={choosenSeason} />;
    }
  };
  return (
    <DynamicPage
      slot={slot}
      style={{
        width: "100%",
      }}
      titleArea={
        <DynamicPageTitle
          breadcrumbs={
            <Breadcrumbs
              onItemClick={(event) => {
                const item = event.detail.item;
                const pathname = item.dataset.href;
                if (!pathname) return;
                navigate({ pathname });
                onChangeLayout(FCLLayout.OneColumn);
                setIsSelection(false);
              }}
            >
              <BreadcrumbsItem data-href="/dashboard/map">
                {getText("FlightList.breadbrumb.Map")}
              </BreadcrumbsItem>
              <BreadcrumbsItem data-href="/dashboard/pigeons">
                {getText("General.MyPigeons")}
              </BreadcrumbsItem>
              {choosenSeason && (
                <BreadcrumbsItem>
                  {getText("General.Season")} ({choosenSeason})
                </BreadcrumbsItem>
              )}
            </Breadcrumbs>
          }
          navigationBar={
            <Toolbar design="Transparent">
              <ToolbarButton
                design="Transparent"
                icon="decline"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Toolbar>
          }
          actionsBar={
            <Toolbar design="Transparent">
              <ToolbarButton
                onClick={onAddPigeon}
                design="Emphasized"
                text={getText("PigeonSeasonPage.AddPigeon")}
              />
              <ToolbarButton
                design="Transparent"
                text={getText("PigeonSeasonPage.PigeonRings")}
                onClick={onOpenRings}
              />
            </Toolbar>
          }
          heading={
            <VariantManagement
              onSelect={(event) => {
                const item = event.detail.targetItem;
                const id = item.dataset.id;
                if (!id) return;
                onChangeLayout(FCLLayout.OneColumn);
                onChangeSeason(Number(id));
                setIsSelection(false);
                navigate(`/dashboard/pigeons/${id}`);
              }}
              closeOnItemSelect
              hideManageVariants
              hideSaveAs
              hideCreatedBy
            >
              <VariantItem
                readOnly
                favorite
                global
                data-id="0"
                selected={!choosenSeason}
              >
                {getText("General.AllPigeons")}
              </VariantItem>
              {seasons?.map((season) => (
                <VariantItem
                  data-id={season.year}
                  key={season.id}
                  readOnly
                  selected={season.year === choosenSeason}
                >{`${getText("General.Season")} (${season.year})`}</VariantItem>
              ))}
            </VariantManagement>
          }
        ></DynamicPageTitle>
      }
    >
      <>
        <FlexBox>
          <VBox
            visible={!isSelection}
            width={breakpoints.isPhone() ? "100%" : "300px"}
          >
            <TextInput
              placeholder={getText("PigeonSeasonPage.SearchByRingNumber")}
              onChange={new Debounce(setSearchValue, 500).exec}
            >
              {getText("General.RingNumber")}
            </TextInput>
          </VBox>
          <VBox
            visible={!choosenSeason && !isSelection}
            Margin={breakpoints.isPhone() ? "" : "0 0 0 10px"}
            width={breakpoints.isPhone() ? "100%" : "300px"}
          >
            <Select
              onSelect={(value) => {
                setActivity(value.key as ActivityEnum);
              }}
              options={[
                new SelectOption({
                  key: ActivityEnum.All,
                  text: getText("General.All"),
                }),
                new SelectOption({
                  key: ActivityEnum.Active,
                  text: getText("General.Active"),
                }),
                new SelectOption({
                  key: ActivityEnum.Inactive,
                  text: getText("General.Inactive"),
                }),
              ]}
              value={1}
            >
              {getText("General.State")}
            </Select>
          </VBox>
        </FlexBox>
        <Toolbar alignContent="Start">
          {!choosenSeason && !isSelection && (
            <>
              <ToolbarButton
                overflowPriority="NeverOverflow"
                onClick={() => {
                  onChangeLayout(FCLLayout.OneColumn);
                  const id = new Date().getFullYear();
                  onChangeSeason(Number(id));
                  navigate(`/dashboard/pigeons/${id}`);
                }}
                design="Attention"
                text={getText("PigeonSeasonPage.GoToSeason")}
              />
              <ToolbarSpacer overflowPriority="NeverOverflow" />
              <ToolbarButton
                overflowPriority="NeverOverflow"
                onClick={() => {
                  onChangeLayout(FCLLayout.OneColumn);
                  setIsSelection(true);
                }}
                icon="add-activity-2"
                text={getText("PigeonSeasonPage.SelectPigeonsForList")}
              />
            </>
          )}
          {!choosenSeason && isSelection && (
            <>
              <ToolbarSpacer overflowPriority="NeverOverflow" />
              <ToolbarButton
                overflowPriority="NeverOverflow"
                onClick={addToSeasonSelection}
                design="Emphasized"
                icon="add"
                text={getText("PigeonSeasonPage.AddSelectedPigeonsToList")}
              />
              <ToolbarButton
                overflowPriority="NeverOverflow"
                onClick={async () => {
                  const confirm = await openMessage(
                    "Na pewno chcesz anulować dodawanie gołębi do spisu?"
                  );
                  if (confirm !== MessageBoxAction.OK) return;

                  onChangeLayout(FCLLayout.OneColumn);
                  setIsSelection(false);
                }}
                icon="decline"
              />
            </>
          )}
          {contentLayout === FCLLayout.OneColumn && !!choosenSeason && (
            <>
              <ToolbarButton
                onClick={() => navigate(`./selections`)}
                text={getText("PigeonSeasonPage.GoToSeason")}
                overflowPriority="NeverOverflow"
              />
              <ToolbarSpacer overflowPriority="NeverOverflow" />
              {breakpoints.isPhone() && (
                <>
                  <ToolbarButton
                    overflowPriority="NeverOverflow"
                    onClick={() => {
                      onChangeAssignMode(PigeonAssignMode.Group);
                      onChangeLayout(FCLLayout.TwoColumnsStartExpanded);
                    }}
                    design="Attention"
                    icon="add-calendar"
                  />
                  <ToolbarButton
                    overflowPriority="NeverOverflow"
                    onClick={() => {
                      onChangeAssignMode(PigeonAssignMode.Pair);
                      onChangeLayout(FCLLayout.TwoColumnsStartExpanded);
                    }}
                    icon="gender-male-and-female"
                  />
                </>
              )}
              <ToolbarButton
                overflowPriority={
                  breakpoints.isPhone() ? "Default" : "NeverOverflow"
                }
                onClick={() => {
                  onChangeAssignMode(PigeonAssignMode.Group);
                  onChangeLayout(FCLLayout.TwoColumnsStartExpanded);
                }}
                design="Attention"
                icon="add-calendar"
                text={getText("PigeonSeasonPage.AddPigeonsToLoft")}
              />
              <ToolbarButton
                overflowPriority={
                  breakpoints.isPhone() ? "Default" : "NeverOverflow"
                }
                onClick={() => {
                  onChangeAssignMode(PigeonAssignMode.Pair);
                  onChangeLayout(FCLLayout.TwoColumnsStartExpanded);
                }}
                icon="gender-male-and-female"
                text={getText("PigeonSeasonPage.AddPairsToSeason")}
              />
            </>
          )}
        </Toolbar>
        {!!choosenSeason && (
          <TabContainer
            onTabSelect={(event) => {
              const tab = event.detail.tab.dataset.tab;
              if (!tab) return;
              setTab(tab as unknown as PigeonTabEnum);
            }}
            collapsed={true}
          >
            {assignMode === PigeonAssignMode.None && (
              <>
                <Tab
                  data-tab={PigeonTabEnum.Pair}
                  text={getText("PigeonSeasonPage.Pairs")}
                ></Tab>
                <TabSeparator />
                <Tab
                  data-tab={PigeonTabEnum.Old}
                  text={getText("PigeonSeasonPage.Adults")}
                ></Tab>
                <Tab
                  data-tab={PigeonTabEnum.Young}
                  text={getText("PigeonSeasonPage.Young")}
                ></Tab>
                <Tab
                  data-tab={PigeonTabEnum.Breeding}
                  text={getText("PigeonSeasonPage.Breeding")}
                ></Tab>
                <TabSeparator />
                <Tab
                  data-tab={PigeonTabEnum.Chick}
                  text={getText("PigeonSeasonPage.Chicks")}
                />
                <Tab
                  data-tab={PigeonTabEnum.Egg}
                  text={getText("PigeonSeasonPage.Eggs")}
                />
              </>
            )}
            {assignMode === PigeonAssignMode.Group && (
              <>
                <Tab
                  data-tab={PigeonTabEnum.Old}
                  text={getText("PigeonSeasonPage.Adults")}
                ></Tab>
                <Tab
                  data-tab={PigeonTabEnum.Young}
                  text={getText("PigeonSeasonPage.Young")}
                ></Tab>
                <Tab
                  data-tab={PigeonTabEnum.Breeding}
                  text={getText("PigeonSeasonPage.Breeding")}
                ></Tab>
              </>
            )}
            {assignMode === PigeonAssignMode.Pair && (
              <Tab
                data-tab={PigeonTabEnum.Pair}
                text={getText("PigeonSeasonPage.Pairs")}
              ></Tab>
            )}
          </TabContainer>
        )}
        {createTable()}
      </>
    </DynamicPage>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "AddPigeonForm": {
      return (
        <AddPigeonForm
          {...(props as WithConfirmDialogProps)}
          isVisible={true}
          onConfirm={(value) => {
            onClose(new DialogResult(MessageBoxAction.OK, value));
          }}
          onClose={(value) => {
            onClose(new DialogResult(MessageBoxAction.Close));
          }}
        />
      );
    }
    case RingDialog.name: {
      return (
        <RingDialog
          isVisible={true}
          onConfirm={() => {}}
          onClose={() => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    }
  }
};

export default memo(
  withDialogs(PigeonSeasonPage, renderDialogs),
  (prev, next) => {
    return (
      prev.choosenSeason === next.choosenSeason &&
      prev.contentLayout === next.contentLayout &&
      prev.assignMode === next.assignMode
    );
  }
);

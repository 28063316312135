import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DynamicPage,
  DynamicPageHeader,
  DynamicPageTitle,
  Form,
  FormGroup,
  FormItem,
  MessageBoxAction,
  Panel,
  Tab,
  TabContainer,
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
  Title,
  Toolbar,
  ToolbarButton,
} from "@ui5/webcomponents-react";
import { useNavigate, useParams } from "react-router-dom";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

import BaseDialogProps from "../models/BaseDialogProps";
import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign";
import ChooseFather from "../components/modals/ChoosePigeon/ChooseFather";
import ChooseMother from "../components/modals/ChoosePigeon/ChooseMother";
import ColorOptions from "../components/modals/AddPigeonForm/ColorOptions";
import DateInput from "../components/DateInput/DateInput";
import { DetailMode } from "../models/enums/DetailMode";
import DialogResult from "../models/DialogResult";
import Formatter from "../utils/Formatter";
import FriendsInput from "../components/FriendsInput";
import Pigeon from "../models/Pigeon";
import PigeonAssignmentCard from "../components/PigeonAssignmentCard";
import PigeonChildrenTable from "../components/PigeonChildrenTable";
import { PigeonColorEnum } from "../models/enums/PigeonColorEnum";
import PigeonEggTable from "../components/PigeonEggTable";
import PigeonGraph from "../components/Graph/PigeonGraph";
import PigeonPairCard from "../components/PigeonPairCard";
import { PigeonSexEnum } from "../models/enums/PigeonSexEnum";
import { PigeonStateEnum } from "../models/enums/PigeonStateEnum";
import { ReactFlowProvider } from "reactflow";
import Ring from "../models/Ring";
import Select from "../components/Select/Select";
import SexOptions from "../components/modals/AddPigeonForm/SexOption";
import StateOptions from "../components/modals/AddPigeonForm/StateOptions";
import TableOverflowMode from "@ui5/webcomponents/dist/types/TableOverflowMode";
import TextInput from "../components/TextInput";
import { TextInputType } from "../styles/ITextInput";
import { VBox } from "../styles/Flexbox.styled";
import WithConfirmDialogProps from "../hoc/withConfirmDialog/WithConfirmDialogProps";
import { WithPigeonHandlerProps } from "../hoc/withPigeonHandler/withPigeonHandlerProps";
import WithToastProps from "../hoc/withToast/WithToastProps";
import { getText } from "../locales/initI18n";
import { setError } from "../redux/modules/error";
import { updatePigeon } from "../services/PigeonService";
import { useAppDispatch } from "../redux/hooks";
import usePigeon from "../hooks/usePigeon";
import usePigeons from "../hooks/usePigeons";
import { useState } from "react";
import withConfirmDialog from "../hoc/withConfirmDialog/withConfirmDialog";
import withNavbar from "../hoc/withNavbar/withNavbar";
import withPigeonHandler from "../hoc/withPigeonHandler/withPigeonHandler";
import withToast from "../hoc/withToast/withToast";

const PigeonView = (
  props: WithToastProps &
    WithDialogsProps &
    WithConfirmDialogProps &
    WithPigeonHandlerProps
) => {
  const { id, season } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mode, setMode] = useState(DetailMode.View);
  const [ringNumber, setRingNumber] = useState("");
  const { invalidate } = usePigeons();

  const { pigeon, setter } = usePigeon(id);

  const isEdit = () => mode === DetailMode.Edit;
  const isView = () => mode === DetailMode.View;
  const isAdd = () => mode === DetailMode.Add;

  const onUpdate = async () => {
    if (!pigeon) return;
    try {
      if (pigeon.ring?.isNew()) {
        const ring = new Ring();
        ring.number = ringNumber;
        setter.ring.set(ring);
      }
      await updatePigeon(pigeon);
      invalidate();
      props.showToast(getText("PigeonView.UpdatedSuccessfully"));
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const chooseFather = async () => {
    if (!pigeon) return;
    const result = await props.openDialog("ChooseFather", { pigeon });
    if (result.isClose()) return;
    const father = result.getData<Pigeon>();
    setter.father.set(father);
  };

  const chooseMother = async () => {
    if (!pigeon) return;
    const result = await props.openDialog("ChooseMother", { pigeon });
    if (result.isClose()) return;
    const mother = result.getData<Pigeon>();
    setter.mother.set(mother);
  };

  const unassignParent = async (isFather: boolean) => {
    const confirm = await props.openMessage(
      getText("PigeonView.ConfirmRemoveParent", {
        parent: isFather
          ? getText("General.father")
          : getText("General.mother"),
        pigeonName: pigeon?.RingNumber,
      })
    );
    if (confirm !== MessageBoxAction.OK) return;
    if (isFather) {
      setter.father.set(new Pigeon());
    } else {
      setter.mother.set(new Pigeon());
    }
  };

  if (!pigeon) return null;
  return (
    <DynamicPage
      style={{
        width: "100%",
      }}
      titleArea={
        <DynamicPageTitle
          breadcrumbs={
            <Breadcrumbs
              onItemClick={(event) => {
                const item = event.detail.item;
                const pathname = item.dataset.href;
                if (pathname) navigate({ pathname });
              }}
            >
              <BreadcrumbsItem data-href="/dashboard/map">Map</BreadcrumbsItem>
              <BreadcrumbsItem data-href="/dashboard/pigeons">
                {getText("General.MyPigeons")}
              </BreadcrumbsItem>
              {season && (
                <BreadcrumbsItem data-href={`/dashboard/pigeons/${season}`}>
                  {getText("General.Season")} ({season})
                </BreadcrumbsItem>
              )}
              <BreadcrumbsItem>{pigeon.RingNumber}</BreadcrumbsItem>
            </Breadcrumbs>
          }
          actionsBar={
            <Toolbar design="Transparent" slot="actionBar">
              {isView() && (
                <>
                  <ToolbarButton
                    design="Emphasized"
                    text={getText("Pigeon.general.edit.title")}
                    onClick={() => setMode(DetailMode.Edit)}
                  />
                  <ToolbarButton
                    onClick={() => props.onRemovePigeon(pigeon)}
                    design="Negative"
                    text="Usuń"
                  />
                </>
              )}
              {isEdit() && (
                <>
                  <ToolbarButton
                    design="Emphasized"
                    icon="save"
                    text={getText("AddFlightForm.Action.SaveChanges")}
                    onClick={() => {
                      onUpdate();
                      setMode(DetailMode.View);
                    }}
                  />
                  <ToolbarButton
                    design="Transparent"
                    icon="cancel"
                    text={getText("AddFlightForm.Action.Cancel")}
                    onClick={() => setMode(DetailMode.View)}
                  />
                </>
              )}
            </Toolbar>
          }
          navigationBar={
            <Toolbar design="Transparent">
              <ToolbarButton
                design="Transparent"
                icon="decline"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Toolbar>
          }
          heading={<Title>{pigeon.name}</Title>}
          snappedHeading={<Title>{pigeon.name}</Title>}
          subheading={
            season ? (
              <Text>
                {getText("General.Season")} ({season})
              </Text>
            ) : null
          }
          snappedSubheading={season ? <Text>Sezon ({season})</Text> : null}
        />
      }
      headerArea={
        <DynamicPageHeader>
          <Form layout="S1 M2 L2 XL2">
            <FormGroup>
              <FormItem
                labelContent={<span>{getText("General.RingNumber")}</span>}
              >
                {isEdit() && pigeon.ring?.isNew() ? (
                  <TextInput
                    type={TextInputType.Text}
                    value={ringNumber}
                    onChange={(value) => setRingNumber(value)}
                  />
                ) : (
                  <Text>{pigeon.ring?.number}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.Name")}</span>}>
                {isEdit() ? (
                  <TextInput
                    type={TextInputType.Text}
                    value={pigeon.name}
                    onChange={(value) => setter.name.set(value)}
                  />
                ) : (
                  <Text>{pigeon.name}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.Breed")}</span>}>
                {isEdit() ? (
                  <TextInput
                    type={TextInputType.Text}
                    value={pigeon.breed}
                    onChange={(value) => setter.breed.set(value)}
                  />
                ) : (
                  <Text>{pigeon.breed}</Text>
                )}
              </FormItem>
              <FormItem
                labelContent={<span>{getText("General.EyeColor")}</span>}
              >
                {isEdit() ? (
                  <TextInput
                    type={TextInputType.Text}
                    value={pigeon.eyeColor}
                    onChange={(value) => setter.eyeColor.set(value)}
                  />
                ) : (
                  <Text>{pigeon.eyeColor}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.Owner")}</span>}>
                {isEdit() ? (
                  <FriendsInput
                    type={TextInputType.Text}
                    value={pigeon.ownerName}
                    onChangeOwner={(value) => value && setter.owner.set(value)}
                    onChange={(value) => setter.ownerName.set(value)}
                  />
                ) : (
                  <Text>{pigeon.ownerName}</Text>
                )}
              </FormItem>
              <FormItem
                labelContent={<span>{getText("General.HatchingDate")}</span>}
              >
                {isEdit() ? (
                  <DateInput
                    value={pigeon.hatchingDate}
                    onChange={(value) => setter.hatchingDate.set(value)}
                  />
                ) : (
                  <Text>{Formatter.Date(pigeon.hatchingDate)}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.Year")}</span>}>
                {isEdit() ? (
                  <TextInput
                    value={pigeon.year}
                    onChange={(value) => setter.year.set(Number(value))}
                  />
                ) : (
                  <Text>{pigeon.year}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.State")}</span>}>
                {isEdit() ? (
                  <Select
                    value={pigeon.state}
                    onSelect={(value) =>
                      setter.state.set(value.key as PigeonStateEnum)
                    }
                    options={StateOptions}
                  />
                ) : (
                  <Text>{pigeon.ReadableState}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.Sex")}</span>}>
                {isEdit() ? (
                  <Select
                    value={pigeon.sex}
                    onSelect={(value) =>
                      setter.sex.set(value.key as PigeonSexEnum)
                    }
                    options={SexOptions}
                  />
                ) : (
                  <Text>{pigeon.ReadableSex}</Text>
                )}
              </FormItem>
              <FormItem labelContent={<span>{getText("General.Color")}</span>}>
                {isEdit() ? (
                  <Select
                    value={pigeon.color}
                    onSelect={(value) =>
                      setter.color.set(value.key as PigeonColorEnum)
                    }
                    options={ColorOptions}
                  />
                ) : (
                  <Text>{pigeon.ReadableColor}</Text>
                )}
              </FormItem>
            </FormGroup>
          </Form>
        </DynamicPageHeader>
      }
    >
      <TabContainer>
        <Tab text={getText("General.Season")}>
          <VBox gap="5px">
            <Panel headerText={getText("General.Seasons")}>
              <PigeonAssignmentCard year={season} pigeon={pigeon} />
            </Panel>
            <Panel headerText={getText("PigeonSeasonPage.Pairs")}>
              <PigeonPairCard year={season} pigeon={pigeon} />
            </Panel>
            <Panel headerText={getText("PigeonSeasonPage.Eggs")}>
              {id && <PigeonEggTable year={season} pigeonId={id} />}
            </Panel>
          </VBox>
        </Tab>
        <Tab text={getText("PigeonDetail.menu.Parents")}>
          <Table
            overflowMode={TableOverflowMode.Popin}
            headerRow={[
              <TableHeaderRow>
                <TableHeaderCell></TableHeaderCell>
                <TableHeaderCell minWidth="200px">
                  {getText("General.RingNumber")}
                </TableHeaderCell>
                <TableHeaderCell minWidth="200px">
                  {getText("General.Name")}
                </TableHeaderCell>
                <TableHeaderCell width="60px"></TableHeaderCell>
                <TableHeaderCell width="60px"></TableHeaderCell>
              </TableHeaderRow>,
            ]}
          >
            <TableRow interactive>
              <TableCell>{getText("General.father")}</TableCell>
              <TableCell>
                {pigeon.father?.RingNumber || getText("Profile.View.Empty")}{" "}
              </TableCell>
              <TableCell>
                {pigeon.father?.name || getText("Profile.View.Empty")}
              </TableCell>
              <TableCell>
                <Button
                  disabled={isView()}
                  onClick={chooseFather}
                  icon="edit"
                  design={ButtonDesign.Emphasized}
                />
              </TableCell>
              <TableCell>
                <Button
                  disabled={isView()}
                  onClick={() => unassignParent(true)}
                  icon="delete"
                  design={ButtonDesign.Negative}
                />
              </TableCell>
            </TableRow>
            <TableRow interactive>
              <TableCell>{getText("General.mother")}</TableCell>
              <TableCell>
                {pigeon.mother?.RingNumber || getText("Profile.View.Empty")}{" "}
              </TableCell>
              <TableCell>
                {pigeon.mother?.name || getText("Profile.View.Empty")}
              </TableCell>
              <TableCell>
                <Button
                  disabled={isView()}
                  onClick={chooseMother}
                  icon="edit"
                  design={ButtonDesign.Emphasized}
                />
              </TableCell>
              <TableCell>
                <Button
                  disabled={isView()}
                  onClick={() => unassignParent(false)}
                  icon="delete"
                  design={ButtonDesign.Negative}
                />
              </TableCell>
            </TableRow>
          </Table>
        </Tab>
        <Tab text={getText("PigeonDetail.menu.Children")}>
          {id && <PigeonChildrenTable pigeonId={id} />}
        </Tab>
        <Tab text={getText("PigeonDetail.menu.Pedigree")}>
          <VBox height="500px">
            <ReactFlowProvider>
              {!pigeon.isNew() && <PigeonGraph pigeon={pigeon} />}
            </ReactFlowProvider>
          </VBox>
        </Tab>
      </TabContainer>
    </DynamicPage>
  );
};

const renderDialogs = (
  dialog: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialog) {
    case "ChooseFather": {
      const pigeon = (props as { pigeon: Pigeon }).pigeon;
      if (!pigeon) throw new Error("Pigeon not found");
      return (
        <ChooseFather
          isVisible
          pigeon={pigeon}
          onClose={() => onClose(new DialogResult(MessageBoxAction.Close))}
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
        />
      );
    }
    case "ChooseMother": {
      const pigeon = (props as { pigeon: Pigeon }).pigeon;
      if (!pigeon) throw new Error("Pigeon not found");
      return (
        <ChooseMother
          isVisible
          pigeon={pigeon}
          onClose={() => onClose(new DialogResult(MessageBoxAction.Close))}
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
        />
      );
    }
  }
};

export default withNavbar(
  withPigeonHandler(
    withDialogs(withConfirmDialog(withToast(PigeonView)), renderDialogs)
  )
);

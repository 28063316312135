export default class RingHelper {
  static getYear(ringNumber: string): number | null {
    ringNumber = ringNumber.replaceAll(" ", "-");
    const splitNumber = ringNumber.split("-");
    if (splitNumber[0] !== "PL") return null;
    if (splitNumber.length <= 3) return null;
    const year = Number(splitNumber?.[2]);
    if (isNaN(year)) return null;
    // change 2 digit year to 4 digit year
    return year + 2000;
  }
}

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { memo, useEffect } from "react";

import DeleteAccount from "./views/DeleteAccount";
import EmailConfirmation from "./views/EmailConfirmation";
import FarmersView from "./views/Farmers.view";
import FlightDetails from "./views/FlightDetails";
import FlightList from "./views/FlightList";
import Home from "./views/Home.view";
import News from "./views/News";
import PasswordReset from "./views/PasswordReset";
import Pigeon from "./views/Pigeon.view";
import PigeonMap from "./views/Map.view";
import PigeonSeasonSelectionPage from "./views/PigeonSeasonSelectionPage";
import PigeonView from "./views/Pigeons.view";
import PolicyPrivacy from "./views/PolicyPrivacy";
import ProfileView from "./views/Profile.view";
import Register from "./views/Register.view";
import { resetAuth } from "./redux/modules/auth";
import { setError } from "./redux/modules/error";
import { useAppDispatch } from "./redux/hooks";
import useUser from "./hooks/useUser";
import withError from "./hoc/withError/withError";

const AppRoutes = () => {
  const protectedRoutes = ["dashboard"];
  const dispatch = useAppDispatch();
  const { data, isSuccess, error, isError } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const width = window.innerWidth;

  const isInProtectedRoute = protectedRoutes.some((route) => {
    return location.pathname.includes(route);
  });

  useEffect(() => {
    if (isError) {
      dispatch(setError(error));
      if (isInProtectedRoute) navigate("/");
    }
  }, [isError]);

  useEffect(() => {
    if (
      location.pathname.includes("login") &&
      location.pathname.split("/").length === 4
    ) {
      dispatch(resetAuth());
      return;
    }
    // if (width >= breakpoints.sm && isInProtectedRoute) {
    //   dispatch(resetAuth());
    //   navigate("/");
    //   return;
    // }
    if (data && !data?.newsIsReadedAt) {
      navigate("/news");
      return;
    }
    if (!isInProtectedRoute && data && data) navigate("/dashboard");
  }, [isSuccess, data]);

  return (
    <Routes>
      <Route path="dashboard">
        <Route path="" element={<Navigate to="map" />} />
        <Route path="map" element={<PigeonMap />} />
        <Route path="map/:flightId" element={<PigeonMap />} />
        <Route path="list/flights" element={<FlightList />} />
        <Route path="list/flights/:flightId" element={<FlightDetails />} />
        <Route
          path="list/flights/:parentFlightId/:flightId"
          element={<FlightDetails />}
        />
        <Route path={"farmers/:username"} element={<FarmersView />} />
        <Route path={"farmers"} element={<FarmersView />} />
        <Route path={"pigeons"} element={<PigeonView />} />
        <Route
          path={"pigeons/:season/selections"}
          element={<PigeonSeasonSelectionPage />}
        />
        <Route path={"pigeons/:season"} element={<PigeonView />} />
        <Route path={"pigeons/:season/:id"} element={<Pigeon />} />
        <Route path={"pigeon/:id"} element={<Pigeon />} />
        <Route path={"profile"} element={<ProfileView />} />
      </Route>
      <Route path={"/"} element={<Home />} />
      <Route path={"/login/:action?/:token?"} element={<Home />} />
      <Route path={"/register"} element={<Register />} />
      <Route path="news" element={<News />} />
      <Route
        path={"/email-confirmation/:token"}
        element={<EmailConfirmation />}
      />
      <Route path={"/password-reset/:token"} element={<PasswordReset />} />
      <Route path={"delete-account/:token?"} element={<DeleteAccount />} />
      <Route path={"policy-privacy"} element={<PolicyPrivacy />} />
    </Routes>
  );
};

export default withError(memo(AppRoutes));

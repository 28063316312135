import { ComponentType, memo } from "react";
import { removePigeon, updatePigeon } from "../../services/PigeonService";

import ConfirmMessageHelper from "../../utils/ConfirmMessageHelper";
import { MessageBoxAction } from "@ui5/webcomponents-react";
import Pigeon from "../../models/Pigeon";
import { PigeonStateEnum } from "../../models/enums/PigeonStateEnum";
import WithConfirmDialogProps from "../withConfirmDialog/WithConfirmDialogProps";
import { WithPigeonHandlerProps } from "./withPigeonHandlerProps";
import WithToastProps from "../withToast/WithToastProps";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import useEggs from "../../hooks/useEggs";
import { useNavigate } from "react-router-dom";
import usePigeonPairs from "../../hooks/usePigeonPairs";
import usePigeons from "../../hooks/usePigeons";
import withConfirmDialog from "../withConfirmDialog/withConfirmDialog";
import withToast from "../withToast/withToast";

function withPigeonHandler<P extends WithPigeonHandlerProps>(
  Component: ComponentType<P>
) {
  const WithComponent = (
    props: Omit<P, keyof WithPigeonHandlerProps> &
      WithToastProps &
      WithConfirmDialogProps
  ) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { invalidate } = usePigeons();
    const { invalidate: invalidatePigeonPairs } = usePigeonPairs();
    const { invalidate: invalidateEggs } = useEggs();

    const onChangeActive = async (pigeon: Pigeon) => {
      try {
        pigeon.isActive = !pigeon.isActive;

        if (!pigeon.isActive) {
          pigeon.state = await ConfirmMessageHelper.getPigeonInActiveState(
            props.openMessage
          );
        } else pigeon.state = PigeonStateEnum.Unknown;

        await updatePigeon(pigeon);
        invalidate();
        props.showToast(
          getText(
            pigeon.isActive
              ? "withPigeonHandler.PigeonActive"
              : "withPigeonHandler.PigeonInactive",
            { ringNumber: pigeon?.RingNumber }
          )
        );
      } catch (e) {
        dispatch(setError(e));
      }
    };

    const onRemovePigeon = async (pigeon: Pigeon) => {
      try {
        const confirm = await props.openMessage(
          `Czy na pewno chcesz usunąć gołębia ${pigeon.RingNumber}?`
        );
        if (confirm !== MessageBoxAction.OK) return;

        await removePigeon(pigeon);
        invalidate();
        invalidatePigeonPairs();
        invalidateEggs();
        navigate(-1);
      } catch (e) {
        dispatch(setError(e));
      }
    };

    return (
      <Component
        {...(props as unknown as P)}
        onChangeActive={onChangeActive}
        onRemovePigeon={onRemovePigeon}
      />
    );
  };

  return memo(withConfirmDialog(withToast(WithComponent)));
}

export default withPigeonHandler;

export enum AddFlightFormModalEnum {
  NONE,
  SELECT_SHARE,
  PLANNER_SHARE,
  PRIVATE_EVENT_SHARE,
  COTES,
  TAGS,
  DELETE_CONFIRM,
  CHANGE_CONFIRM,
  SUBMIT_CONFIRM,
  REJECT_SHARE,
  RELATED_FLIGHTS,
  FINISH_FLIGHT,
  FINISH_EVENT_FLIGHT
}

import { Button, ButtonType } from "../styles/Button.styled";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Field, FieldInstance, Form, FormInstance } from "houseform";
import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";
import { Link, useNavigate } from "react-router-dom";
import Text, { Bold } from "../components/Text/Text";
import { googleSignIn, signUp } from "../services/AuthService";
import { useEffect, useRef, useState } from "react";
import {
  validateConfirmPassword,
  validateEmail,
  validateGmail,
  validateLastName,
  validateName,
  validateObjectRequired,
  validatePassword,
  validatePhoneNumber,
  validateTrueBoolean,
  validateUsername,
} from "../utils/ValidationHelper";

import { Branch } from "../models/Branch";
import BranchList from "../components/modals/BranchList/BranchList";
import Busy from "../components/Busy";
import Checkbox from "../components/Checkbox";
import { Country } from "../models/Country";
import CountryList from "../components/CountryList/CountryList";
import { District } from "../models/District";
import DistrictList from "../components/modals/DistrictList/DistrictList";
import Logo from "../components/Logo/Logo";
import RegionDetector from "../locales/RegionDetector";
import RegisterConfirmation from "../components/modals/RegisterConfirmation/RegisterConfirmation";
import { RegisterFormProps } from "../services/IAuthService";
import { Section } from "../models/Section";
import SectionList from "../components/modals/SectionList/SectionList";
import TagButton from "../components/TagButton/TagButton";
import TextInput from "../components/TextInput";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { getText } from "../locales/initI18n";
import { setAuth } from "../redux/modules/auth";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import { useMutation } from "@tanstack/react-query";
import { useTheme } from "styled-components";

export default function Register() {
  const theme = useTheme();
  const gmailValidator = validateGmail();
  const form = useRef<FormInstance<RegisterFormProps>>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [countryCode, setCountryCode] = useState<string>();
  const [distinctNumber, setDistinctNumber] = useState<number>(0);

  const [district, setDistrict] = useState<District | null>(null);
  const [branch, setBranch] = useState<Branch | null>(null);
  const [section, setSection] = useState<Section | null>(null);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isDistrictList, setIsDistrictList] = useState(false);
  const [isBranchList, setIsBranchList] = useState(false);
  const [isSectionList, setIsSectionList] = useState(false);

  const [isGmail, setIsGmail] = useState(false);

  const [busy, setBusy] = useState(false);

  const SignUp = useMutation({
    mutationFn: signUp,
    onSuccess: () => {
      setIsConfirmationVisible(true);
    },
    onError: (error) => {
      dispatch(setError(error));
    },
  });
  const GoogleSignIn = useMutation({
    mutationFn: googleSignIn,
  });
  const onSubmit = (data: RegisterFormProps) => {
    data.acceptPrivayPolicy = undefined;
    data.country = countryCode || "";
    if (district?.country !== countryCode) {
      data.district = undefined;
      data.branch = undefined;
      data.section = undefined;
    }
    data.email = data.email.trim();
    data.username = data.username.trim();
    SignUp.mutate(data);
  };

  const onGoogleLogin = async (variables: CredentialResponse) => {
    if (!variables.credential) return;
    setBusy(true);
    const params = {
      token: variables.credential,
      country: RegionDetector.GetUserCountry().code,
    };
    try {
      const { data } = await GoogleSignIn.mutateAsync(params);
      dispatch(setAuth({ token: data.access_token }));
      navigate("/dashboard");
    } catch (e) {
      dispatch(setError(e));
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    if (!form.current) return;
    const countryField = form.current.getFieldValue("country") as FieldInstance<
      Country,
      RegisterFormProps
    >;
    if (countryField) {
      const userCountry = RegionDetector.GetUserCountry();
      setCountryCode(userCountry.code);
      countryField.setValue(userCountry);
    }
  }, []);
  return (
    <>
      <RegisterConfirmation isVisible={isConfirmationVisible} />
      <DistrictList
        isVisible={isDistrictList}
        onClose={() => setIsDistrictList(false)}
        country={countryCode}
        distinctCount={(count) => setDistinctNumber(count)}
        onConfirm={(data) => {
          setDistrict(data);
          setIsDistrictList(false);
        }}
      />
      <BranchList
        isVisible={isBranchList}
        onClose={() => setIsBranchList(false)}
        onConfirm={(data) => {
          setBranch(data);
          setIsBranchList(false);
        }}
        districtId={district?.id}
      />
      <SectionList
        isVisible={isSectionList}
        onClose={() => setIsSectionList(false)}
        onConfirm={(data) => {
          setSection(data);
          setIsSectionList(false);
        }}
        districtId={district?.id}
        branchId={branch?.id}
      />
      <VBox
        height="auto"
        overflowY="auto"
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        backgroundColor={theme.palette.White}
        Padding="0 0 40px 0"
      >
        <Busy visible={busy} />
        <Logo />
        <ResponsiveVBox
          backgroundColor={theme.palette.White}
          shadow="small"
          RMinWidthSM="400px"
          minWidth="100%"
          RIsShadowSM={false}
          BorderRadius="20px"
          alignItems="center"
          Padding="20px"
          gap="20px"
          maxWidth="480px"
          Margin="0 5px"
        >
          <Text
            type="h4"
            size="large"
            weight="medium"
            color={theme.palette.Grey90}
          >
            {getText("Register")}
          </Text>
          <Form onSubmit={onSubmit} ref={form}>
            {({ submit, value, getFieldValue }) => (
              <>
                <Field<string>
                  name="username"
                  onSubmitValidate={validateUsername()}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        width="400px"
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => setValue(e)}
                        placeholder="jan123"
                        errors={errors}
                      >
                        {getText("Username")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<string> name="email" onSubmitValidate={validateEmail()}>
                  {({ value, setValue, onBlur, isValid, errors }) => {
                    const IsGmail = gmailValidator.safeParse(value).success;
                    if (IsGmail !== isGmail) {
                      setIsGmail(IsGmail);
                    }
                    return (
                      <HBox width="100%" gap="10px">
                        <TextInput
                          width="400px"
                          isErrorState={!isValid}
                          value={value.trim()}
                          onBlur={onBlur}
                          onChange={(e) => setValue(e)}
                          placeholder="jan.kowalski@email.com"
                          errors={errors}
                        >
                          {getText("Email")}
                        </TextInput>
                      </HBox>
                    );
                  }}
                </Field>
                <VBox
                  // visible={isGmail}
                  visible={false}
                  Padding="10px"
                  backgroundColor={theme.palette.Green10}
                  border={`2px solid ${theme.palette.Green90}`}
                  BorderRadius="10px"
                >
                  <Text
                    type="h5"
                    size="large"
                    weight="medium"
                    color={theme.palette.Green100}
                  >
                    Szybkie logowanie
                  </Text>
                  <Text
                    type="caption"
                    size="large"
                    weight="regular"
                    color={theme.palette.Green100}
                    textAlign="justify"
                  >
                    Wpisany email jest z domeny <Bold>gmail.com</Bold> i może
                    być wykorzystany do logowania przez Google. Naciśnij
                    przycisk <Bold>Zaloguj się przez Google</Bold> aby
                    natychmiastowo zarejestrować się i przejść do aplikacji. W
                    przypadku skorzystania z tej możliwości nie będziesz musiał
                    podawać hasła oraz <Bold>weryfikować adresu email</Bold>.
                  </Text>
                  <HBox Margin="10px 0 0 0">
                    {/* <GoogleLogin onSuccess={onGoogleLogin} /> */}
                  </HBox>
                </VBox>
                <Field<string>
                  name="password"
                  onSubmitValidate={validatePassword()}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        width="400px"
                        type="password"
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => setValue(e)}
                        placeholder="******"
                        errors={errors}
                      >
                        {getText("Password")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<string>
                  name="passwordRepeat"
                  onSubmitValidate={validateConfirmPassword(value.password)}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        width="400px"
                        type="password"
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => setValue(e)}
                        placeholder="******"
                        errors={errors}
                      >
                        {getText("PasswordRepeat")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<Country | {}>
                  name="country"
                  onSubmitValidate={validateObjectRequired()}
                >
                  {({ value, setValue, errors }) => (
                    <HBox width="100%" zIndex={1}>
                      <CountryList
                        country={value as Country}
                        errors={errors}
                        onSelect={(region) => {
                          setValue(region);
                          setCountryCode((region as Country)?.code);
                        }}
                      />
                    </HBox>
                  )}
                </Field>
                <Field<District>
                  name="district"
                  onSubmitValidate={
                    !!distinctNumber ? validateObjectRequired() : undefined
                  }
                  onChangeValidate={
                    !!distinctNumber ? validateObjectRequired() : undefined
                  }
                >
                  {({ value, setValue, errors }) => {
                    if (district !== null && !district.isMatched(value)) {
                      setValue(district);
                    }
                    return (
                      <HBox width="100%" gap="10px" visible={!!distinctNumber}>
                        <TagButton
                          title={getText("General.District")}
                          placeholder={getText(
                            "Register.DistrictSetPlaceholder"
                          )}
                          value={value.name}
                          error={errors[0]}
                          onClick={() => {
                            setIsDistrictList(true);
                          }}
                          disabled={false}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<Branch>
                  name="branch"
                  onSubmitValidate={
                    !!distinctNumber ? validateObjectRequired() : undefined
                  }
                  onChangeValidate={
                    !!distinctNumber ? validateObjectRequired() : undefined
                  }
                >
                  {({ value: branchValue, setValue, errors }) => {
                    if (branch !== null && !branch.isMatched(branchValue)) {
                      setValue(branch);
                    }
                    return (
                      <HBox width="100%" gap="10px" visible={!!distinctNumber}>
                        <TagButton
                          title={getText("General.Branch")}
                          placeholder={getText("Register.BranchSetPlaceholder")}
                          value={branchValue?.name}
                          error={
                            !!branchValue &&
                            branchValue?.districtId !== district?.id
                              ? getText("Register.BranchError")
                              : errors[0]
                          }
                          onClick={() => {
                            setIsBranchList(true);
                          }}
                          disabled={!value?.district}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<Section>
                  name="section"
                  onSubmitValidate={
                    !!distinctNumber ? validateObjectRequired() : undefined
                  }
                  onChangeValidate={
                    !!distinctNumber ? validateObjectRequired() : undefined
                  }
                >
                  {({ value: sectionValue, setValue, errors }) => {
                    if (section !== null && !section.isMatched(sectionValue)) {
                      setValue(section);
                    }
                    return (
                      <HBox width="100%" gap="10px" visible={!!distinctNumber}>
                        <TagButton
                          title={getText("General.Section")}
                          placeholder={getText(
                            "Register.SectionSetPlaceholder"
                          )}
                          value={sectionValue?.name}
                          error={
                            !!sectionValue &&
                            sectionValue?.branchId !== branch?.id
                              ? getText("Register.SectionError")
                              : errors[0]
                          }
                          onClick={() => {
                            setIsSectionList(true);
                          }}
                          disabled={!value?.branch}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<string>
                  name="firstName"
                  onSubmitValidate={validateName(true)}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        width="400px"
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => setValue(e)}
                        placeholder="Jan"
                        errors={errors}
                      >
                        {`${getText("FirstName")}*`}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<string>
                  name="lastName"
                  onSubmitValidate={validateLastName(true)}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        width="400px"
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => setValue(e)}
                        placeholder="Kowalski"
                        errors={errors}
                      >
                        {`${getText("LastName")}*`}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<string>
                  name="phoneNumber"
                  onSubmitValidate={validatePhoneNumber(true)}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        width="400px"
                        isErrorState={!isValid}
                        x
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => setValue(e)}
                        placeholder="+48517775332"
                        errors={errors}
                      >
                        {`${getText("PhoneNumber")}*`}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<boolean>
                  name="acceptPrivayPolicy"
                  onSubmitValidate={validateTrueBoolean()}
                  initialValue={false}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <VBox width="100%">
                      <HBox
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text
                          type={TextTypeEnum.caption}
                          size={TextSizeEnum.large}
                          weight={TextWeightEnum.regular}
                        >
                          Akceptuję{" "}
                          <Link target="_blank" to="/policy-privacy">
                            politykę prywatności
                          </Link>
                        </Text>
                        <Checkbox
                          state={value}
                          onClick={() => setValue(!value)}
                          title=""
                        />
                      </HBox>
                      <VBox
                        visible={!isValid}
                        backgroundColor={theme.palette.Red80}
                        BorderRadius="10px"
                        Padding="2px 0 2px 5px"
                        Margin="5px 0 0 0"
                      >
                        <Text
                          color={theme.palette.White}
                          type={TextTypeEnum.caption}
                          size={TextSizeEnum.large}
                          weight={TextWeightEnum.regular}
                        >
                          {errors?.[0]}
                        </Text>
                      </VBox>
                    </VBox>
                  )}
                </Field>
                <HBox width="100%" gap="20px">
                  <Text type="caption" size="large" weight="regular">
                    {getText("OptionalFields")}
                  </Text>
                </HBox>
                <Button
                  onClick={submit}
                  buttonType={ButtonType.Primary}
                  width="100%"
                >
                  {getText("Register")}
                </Button>
                <Text
                  type="body"
                  size="large"
                  weight="bold"
                  color={theme.palette.Grey70}
                  textAlign="center"
                >
                  {getText("OR")}
                </Text>
                <Button
                  buttonType={ButtonType.Ghost}
                  width="100%"
                  onClick={() => navigate("/")}
                >
                  {getText("Return")}
                </Button>
              </>
            )}
          </Form>
        </ResponsiveVBox>
      </VBox>
    </>
  );
}

import { authDelete, authGet, authPost } from "./BaseService";

import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import PigeonSeasonSelection from "../models/PigeonSeasonSelection";

export const getSeasonSelections = async (options: PageOptions) => {
  const result = await authGet<object[]>(
    `pigeon-season-selection?${options.getParams()}`
  );

  const page = new Page<PigeonSeasonSelection>().fillData(result.data);
  page.data = page.data.map((item) => PigeonSeasonSelection.create(item));
  return page;
};

export const getNotSelectedPigeons = async (
  year: number,
  options: PageOptions
) => {
  const result = await authGet<object[]>(
    `pigeon-season-selection/not-selected-for-season/${year}?${options.getParams()}`
  );

  const page = new Page<Pigeon>().fillData(result.data);
  page.data = page.data.map((item) => Pigeon.create(item));
  return page;
};

export const addToSelection = (pigeonId: number, year: number) => {
  return authPost("pigeon-season-selection", { pigeonId, year });
};

export const removeFromSelection = (selectionId: number) => {
  return authDelete(`pigeon-season-selection/${selectionId}`);
};

export const moveInSelection = (fromId: number, toId: number) => {
  return authPost("pigeon-season-selection/move", {
    fromSelectionId: fromId,
    toSelectionId: toId,
  });
};

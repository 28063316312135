import { Base } from "./Base";
import Pigeon from "./Pigeon";
import { PigeonSeasonSelectionGroup } from "./enums/PigeonSeasonSelectionGroup";
import Season from "./Season";
import User from "./User";

export default class PigeonSeasonSelection extends Base {
  pigeon!: Pigeon;
  pigeonId!: number;
  season!: Season;
  seasonId!: number;
  user!: User;
  userId!: number;
  position!: number;
  group!: PigeonSeasonSelectionGroup;

  fillData(data: object): this {
    super.fillData(data);
    if (this.pigeon) this.pigeon = Pigeon.create(this.pigeon);
    if (this.season) this.season = Season.create(this.season);
    if (this.user) this.user = User.create(this.user);
    return this;
  }
}

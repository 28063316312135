import { removeFather, removeMother } from "../services/PigeonService";

import Confirm from "./modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../models/enums/ConfirmModalTypeEnum";
import { ModalProps } from "./modals/IModalOptions";
import Pigeon from "../models/Pigeon";
import { PigeonSexEnum } from "../models/enums/PigeonSexEnum";
import { getText } from "../locales/initI18n";

export interface ConfirmRemoveDependencyParentProps
  extends ModalProps<unknown> {
  parent: Pigeon;
  pigeon: Pigeon;
}

const ConfirmRemoveDependencyParent = ({
  parent,
  pigeon,
  onClose,
  onConfirm,
  isVisible,
}: ConfirmRemoveDependencyParentProps) => {
  const onRemoveDependency = async () => {
    if (parent.sex === PigeonSexEnum.Male) {
      await removeFather(pigeon);
      pigeon.removeFather();
    } else {
      await removeMother(pigeon);
      pigeon.removeMother();
    }
    onConfirm(undefined);
  };
  if (!parent || !pigeon) return null;
  return (
    <Confirm
      type={ConfirmModalTypeEnum.Delete}
      isVisible={isVisible}
      title={getText("ConfirmRemoveDependencyParent.title")}
      description={`${getText("ConfirmRemoveDependencyParent.description")} ${
        parent.sex === PigeonSexEnum.Male
          ? getText("General.father")
          : getText("General.mother")
      }?
        ${getText("ConfirmRemoveDependencyParent.deleteNotDeletePigeon")}.
        ${getText("ConfirmRemoveDependencyParent.Dependency")}: ${
        pigeon?.RingNumber
      } -> ${
        parent.sex === PigeonSexEnum.Male
          ? pigeon.father?.RingNumber
          : pigeon.mother?.RingNumber
      }`}
      onConfirm={onRemoveDependency}
      onClose={onClose}
    />
  );
};

export default ConfirmRemoveDependencyParent;

import { AddPigeonFormData, AddPigeonFormProps } from "./IAddPigeonForm";
import { Button, ButtonType } from "../../../styles/Button.styled";
import ColorOptions, { getInitialColor } from "./ColorOptions";
import { Field, FieldInstance, Form, FormInstance } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import {
  Panel,
  SuggestionItem,
  CheckBox as UICheckbox,
} from "@ui5/webcomponents-react";
import SexOptions, { getInitialSex } from "./SexOption";
import StateOptions, { getInitialState } from "./StateOptions";
import { useRef, useState } from "react";

import DateInput from "../../DateInput/DateInput";
import Debounce from "../../../utils/Debounce";
import Formatter from "../../../utils/Formatter";
import FriendsInput from "../../FriendsInput";
import HalfModal from "../../HalfModal/HalfModal";
import { IFarmer } from "../../../services/IFollowService";
import PageOptions from "../../../models/PageOptions";
import Pigeon from "../../../models/Pigeon";
import { PigeonColorEnum } from "../../../models/enums/PigeonColorEnum";
import { PigeonSexEnum } from "../../../models/enums/PigeonSexEnum";
import { PigeonStateEnum } from "../../../models/enums/PigeonStateEnum";
import RingHelper from "../../../utils/RingHelper";
import Select from "../../Select/Select";
import SelectOption from "../../../models/SelectOption";
import TextFormInput from "../../Form/TextFormInput";
import TextInput from "../../TextInput";
import { getRings } from "../../../services/RingService";
import { getText } from "../../../locales/initI18n";
import { setError } from "../../../redux/modules/error";
import { useAppDispatch } from "../../../redux/hooks";
import usePigeons from "../../../hooks/usePigeons";
import useUser from "../../../hooks/useUser";
import { validateRequired } from "../../../utils/ValidationHelper";

const AddPigeonForm = ({ pigeon, ...props }: AddPigeonFormProps) => {
  const form = useRef<FormInstance<AddPigeonFormData>>(null);
  const [owner, setOwner] = useState<IFarmer | undefined>(undefined);
  const { data: user } = useUser();
  const [ringSuggestions, setRingSuggestions] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { removeCaches } = usePigeons(new PageOptions());

  const onSubmit = async (data: AddPigeonFormData) => {
    try {
      const addedPigoen = new Pigeon().fillData(data);
      addedPigoen.name = addedPigoen.name || data.ring;
      addedPigoen.color = data.color.key as PigeonColorEnum;
      addedPigoen.sex = data.sex.key as PigeonSexEnum;
      addedPigoen.state = data.state.key as PigeonStateEnum;
      if (owner) {
        addedPigoen.ownerId = owner.id;
      }
      addedPigoen.ownerName = data.owner || user?.username || "";
      addedPigoen.prepareForSave();
      props.onConfirm(addedPigoen);
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const onChangeRing = async (value: string) => {
    if (value.length < 3) {
      setRingSuggestions([]);
      return;
    }
    const options = new PageOptions({
      page: 1,
      take: 5,
      filters: `number=='=LIKE=${value}=LIKE='`,
    });
    const rings = await getRings(options);
    const ringSuggestions = rings.data.map((ring) => ring.number);
    setRingSuggestions(ringSuggestions);
  };

  const ringDebounce = useRef<Debounce>(new Debounce(onChangeRing, 500));

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      title={
        pigeon
          ? getText("AddPigeonForm.modal.title.edit")
          : getText("AddPigeonForm.modal.title")
      }
      description={getText("AddPigeonForm.modal.description")}
      isExpander={false}
      heightDivider={1.1}
    >
      <VBox>
        <Form onSubmit={onSubmit} ref={form}>
          {({ submit, value: allValue }) => (
            <>
              <VBox Padding="0px 20px 0 20px" gap="10px">
                <Field<string>
                  name="ring"
                  initialValue={pigeon?.ring.number || ""}
                  onSubmitValidate={validateRequired()}
                >
                  {(props) => {
                    return (
                      <TextInput
                        onChange={(value) => {
                          console.log(value);
                          props.setValue(value);
                          ringDebounce.current.exec(value);
                          const YearField = form.current?.getFieldValue(
                            "year"
                          ) as FieldInstance<any, AddPigeonFormData>;
                          const year = RingHelper.getYear(value);
                          console.log(year);
                          if (year) {
                            YearField?.setValue(year.toString());
                          }
                        }}
                        style={{ width: "100%" }}
                        placeholder="PL-0000-0000-0000"
                        {...props}
                        showSuggestions={true}
                        suggestions={ringSuggestions.map((ring) => (
                          <SuggestionItem
                            key={`AddPigeonForm-ringSuggetions-${ring}`}
                            text={ring}
                          />
                        ))}
                      >
                        {getText("General.RingNumber")}
                      </TextInput>
                    );
                  }}
                </Field>
                <Field<string>
                  name="year"
                  initialValue={
                    pigeon?.year?.toString?.() || Formatter.Year(new Date())
                  }
                >
                  {(props) => {
                    return (
                      <HBox>
                        <TextInput
                          type="number"
                          onChange={(e) => {
                            props.setValue(e);
                            const hatchingDateField =
                              form.current?.getFieldValue(
                                "hatchingDate"
                              ) as FieldInstance<any, AddPigeonFormData>;
                            hatchingDateField?.setValue("");
                          }}
                          placeholder={getText(
                            "AddPigeonForm.form.year.placeholder"
                          )}
                          {...props}
                        >
                          {getText("AddPigeonForm.form.year.title")}
                        </TextInput>
                      </HBox>
                    );
                  }}
                </Field>
                <Panel headerText="Pola opcjonalne" collapsed>
                  <VBox gap="10px">
                    <Field<string>
                      name="name"
                      initialValue={pigeon?.name || ""}
                    >
                      {(props) => {
                        return (
                          <TextFormInput
                            title={getText("AddPigeonForm.form.name.title")}
                            placeholder={getText(
                              "AddPigeonForm.form.name.placeholder"
                            )}
                            {...props}
                          />
                        );
                      }}
                    </Field>
                    <Field<SelectOption>
                      name="sex"
                      initialValue={getInitialSex(pigeon?.sex)}
                    >
                      {({ value, setValue, errors, isValid, onBlur }) => {
                        return (
                          <HBox width="100%" gap="10px" zIndex={2}>
                            <Select
                              value={value}
                              onSelect={setValue}
                              options={SexOptions}
                              errors={errors}
                              children={getText("General.Sex")}
                            />
                          </HBox>
                        );
                      }}
                    </Field>
                    <Field<string | Date>
                      name="hatchingDate"
                      initialValue={pigeon?.hatchingDate || ""}
                    >
                      {({ value, setValue, onBlur, isValid, errors }) => (
                        <HBox width="100%" gap="10px">
                          <DateInput
                            isErrorState={!isValid}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e: Date) => {
                              setValue(e);
                              const yearField = form.current?.getFieldValue(
                                "year"
                              ) as FieldInstance<any, AddPigeonFormData>;
                              yearField?.setValue(Formatter.Year(e));
                            }}
                            placeholder={getText(
                              "AddPigeonForm.form.hatchingDate.placeholder"
                            )}
                            type="date"
                            errors={errors}
                          >
                            {getText("AddPigeonForm.form.hatchingDate.title")}
                          </DateInput>
                        </HBox>
                      )}
                    </Field>
                    <Field<SelectOption>
                      name="color"
                      initialValue={getInitialColor(pigeon?.color)}
                    >
                      {({ value, setValue, errors, isValid, onBlur }) => {
                        return (
                          <HBox width="100%" gap="10px" zIndex={1}>
                            <Select
                              fixedHeight="250px"
                              value={value}
                              onSelect={setValue}
                              options={ColorOptions}
                              errors={errors}
                              children={getText("General.Color")}
                            />
                          </HBox>
                        );
                      }}
                    </Field>
                    <Field<SelectOption>
                      name="state"
                      initialValue={getInitialState(pigeon?.state)}
                    >
                      {({ value, setValue, errors, isValid, onBlur }) => {
                        return (
                          <HBox width="100%" gap="10px" zIndex={1}>
                            <Select
                              fixedHeight="250px"
                              value={value}
                              onSelect={setValue}
                              options={StateOptions}
                              errors={errors}
                              children={getText("General.State")}
                            />
                          </HBox>
                        );
                      }}
                    </Field>
                    <Field<string>
                      name="breed"
                      initialValue={pigeon?.breed || ""}
                    >
                      {(props) => {
                        return (
                          <HBox>
                            <TextFormInput
                              title={getText("AddPigeonForm.form.breed.title")}
                              placeholder=""
                              {...props}
                            />
                          </HBox>
                        );
                      }}
                    </Field>
                    <Field<string>
                      name="eyeColor"
                      initialValue={pigeon?.eyeColor || ""}
                    >
                      {(props) => {
                        return (
                          <HBox>
                            <TextFormInput
                              title={getText(
                                "AddPigeonForm.form.eyeColor.title"
                              )}
                              placeholder={""}
                              {...props}
                            />
                          </HBox>
                        );
                      }}
                    </Field>
                    <Field<string>
                      name="owner"
                      initialValue={pigeon?.OwnerName || user?.username}
                    >
                      {(props) => {
                        return (
                          <HBox>
                            <FriendsInput
                              {...props}
                              onChange={(value) => {
                                props.setValue(value);
                              }}
                              onChangeOwner={(value) => {
                                setOwner(value);
                              }}
                            >
                              {getText("AddPigeonForm.form.owner.title")}
                            </FriendsInput>
                          </HBox>
                        );
                      }}
                    </Field>
                  </VBox>
                </Panel>
                <Button
                  onClick={submit}
                  buttonType={ButtonType.Primary}
                  width="100%"
                >
                  {pigeon
                    ? getText("AddPigeonForm.form.editButton.title")
                    : getText("AddPigeonForm.form.addButton.title")}
                </Button>
              </VBox>
            </>
          )}
        </Form>
      </VBox>
    </HalfModal>
  );
};

export default AddPigeonForm;
